import { CountryISO } from "ngx-intl-tel-input-gg";

export const countries = [
    [
        'Afghanistan',
        CountryISO.Afghanistan,
        '93'
    ],
    [
        'Albania',
        CountryISO.Albania,
        '355'
    ],
    [
        'Algeria',
        CountryISO.Algeria,
        '213'
    ],
    [
        'American Samoa',
        'as',
        '1',
        1,
        [
            '684',
        ]
    ],
    [
        'Andorra',
        CountryISO.Andorra,
        '376'
    ],
    [
        'Angola',
        CountryISO.Angola,
        '244'
    ],
    [
        'Anguilla',
        'ai',
        '1',
        1,
        [
            '264',
        ]
    ],
    [
        'Antigua and Barbuda',
        'ag',
        '1',
        1,
        [
            '268',
        ]
    ],
    [
        'Argentina',
        CountryISO.Argentina,
        '54'
    ],
    [
        'Armenia',
        CountryISO.Armenia,
        '374'
    ],
    [
        'Aruba',
        CountryISO.Aruba,
        '297'
    ],
    [
        'Australia',
        CountryISO.Australia,
        '61',
        0
    ],
    [
        'Austria',
        CountryISO.Austria,
        '43'
    ],
    [
        'Azerbaijan',
        CountryISO.Azerbaijan,
        '994'
    ],
    [
        'Bahamas',
        'bs',
        '1',
        1,
        [
            '242',
        ]
    ],
    [
        'Bahrain',
        CountryISO.Bahrain,
        '973'
    ],
    [
        'Bangladesh',
        CountryISO.Bangladesh,
        '880'
    ],
    [
        'Barbados',
        'bb',
        '1',
        1,
        [
            '246',
        ]
    ],
    [
        'Belarus',
        CountryISO.Belarus,
        '375'
    ],
    [
        'Belgium',
        CountryISO.Belgium,
        '32'
    ],
    [
        'Belize',
        CountryISO.Belize,
        '501'
    ],
    [
        'Benin',
        CountryISO.Benin,
        '229'
    ],
    [
        'Bermuda',
        'bm',
        '1',
        1,
        [
            '441',
        ]
    ],
    [
        'Bhutan',
        CountryISO.Bhutan,
        '975'
    ],
    [
        'Bolivia',
        CountryISO.Bolivia,
        '591'
    ],
    [
        'Bosnia and Herzegovina',
        CountryISO.BosniaAndHerzegovina,
        '387'
    ],
    [
        'Botswana',
        CountryISO.Botswana,
        '267'
    ],
    [
        'Brazil',
        CountryISO.Brazil,
        '55'
    ],
    [
        'British Indian Ocean Territory',
        CountryISO.BritishIndianOceanTerritory,
        '246'
    ],
    [
        'British Virgin Islands',
        'vg',
        '1',
        1,
        [
            '284',
        ]
    ],
    [
        'Brunei',
        CountryISO.Brunei,
        '673'
    ],
    [
        'Bulgaria',
        CountryISO.Bulgaria,
        '359'
    ],
    [
        'Burkina Faso',
        CountryISO.BurkinaFaso,
        '226'
    ],
    [
        'Burundi',
        CountryISO.Burundi,
        '257'
    ],
    [
        'Cambodia',
        CountryISO.Cambodia,
        '855'
    ],
    [
        'Cameroon',
        CountryISO.Cameroon,
        '237'
    ],
    [
        'Canada',
        CountryISO.Canada,
        '1',
        1,
        [
            '204', '226', '236', '249', '250', '289', '306', '343', '365', '387', '403', '416',
            '418', '431', '437', '438', '450', '506', '514', '519', '548', '579', '581', '587',
            '604', '613', '639', '647', '672', '705', '709', '742', '778', '780', '782', '807',
            '819', '825', '867', '873', '902', '905'
        ]
    ],
    [
        'Cape Verde',
        CountryISO.CapeVerde,
        '238'
    ],
    [
        'Caribbean Netherlands',
        CountryISO.CaribbeanNetherlands,
        '599',
        1
    ],
    [
        'Cayman Islands',
        'ky',
        '1',
        1,
        [
            '345',
        ]
    ],
    [
        'Central African Republic',
        CountryISO.CentralAfricanRepublic,
        '236'
    ],
    [
        'Chad',
        CountryISO.Chad,
        '235'
    ],
    [
        'Chile',
        CountryISO.Chile,
        '56'
    ],
    [
        'China',
        CountryISO.China,
        '86'
    ],
    [
        'Christmas Island',
        CountryISO.ChristmasIsland,
        '61',
        2
    ],
    [
        'Cocos Islands',
        CountryISO.Cocos,
        '61',
        1
    ],
    [
        'Colombia',
        CountryISO.Colombia,
        '57'
    ],
    [
        'Comoros',
        CountryISO.Comoros,
        '269'
    ],
    [
        'Congo DRC',
        CountryISO.CongoDRCJamhuriYaKidemokrasiaYaKongo,
        '243'
    ],
    [
        'Congo Republic',
        CountryISO.CongoRepublicCongoBrazzaville,
        '242'
    ],
    [
        'Cook Islands',
        CountryISO.CookIslands,
        '682'
    ],
    [
        'Costa Rica',
        CountryISO.CostaRica,
        '506'
    ],
    [
        'Côte dIvoire',
        CountryISO.CôteDIvoire,
        '225'
    ],
    [
        'Croatia',
        CountryISO.Croatia,
        '385'
    ],
    [
        'Cuba',
        CountryISO.Cuba,
        '53'
    ],
    [
        'Curaçao',
        CountryISO.Curaçao,
        '599',
        0
    ],
    [
        'Cyprus',
        CountryISO.Cyprus,
        '357'
    ],
    [
        'Czech Republic',
        CountryISO.CzechRepublic,
        '420'
    ],
    [
        'Denmark',
        CountryISO.Denmark,
        '45'
    ],
    [
        'Djibouti',
        CountryISO.Djibouti,
        '253'
    ],
    [
        'Dominica',
        CountryISO.Dominica,
        '1767'
    ],
    [
        'Dominican Republic',
        CountryISO.DominicanRepublic,
        '1',
        2,
        ['809', '829', '849']
    ],
    [
        'Ecuador',
        CountryISO.Ecuador,
        '593'
    ],
    [
        'Egypt',
        CountryISO.Egypt,
        '20'
    ],
    [
        'El Salvador',
        CountryISO.ElSalvador,
        '503'
    ],
    [
        'Equatorial Guinea',
        CountryISO.EquatorialGuinea,
        '240'
    ],
    [
        'Eritrea',
        CountryISO.Eritrea,
        '291'
    ],
    [
        'Estonia',
        CountryISO.Estonia,
        '372'
    ],
    [
        'Ethiopia',
        CountryISO.Ethiopia,
        '251'
    ],
    [
        'Falkland Islands',
        CountryISO.FalklandIslands,
        '500'
    ],
    [
        'Faroe Islands',
        CountryISO.FaroeIslands,
        '298'
    ],
    [
        'Fiji',
        CountryISO.Fiji,
        '679'
    ],
    [
        'Finland',
        CountryISO.Finland,
        '358',
        0
    ],
    [
        'France',
        CountryISO.France,
        '33'
    ],
    [
        'French Guiana',
        CountryISO.FrenchGuiana,
        '594'
    ],
    [
        'French Polynesia',
        CountryISO.FrenchPolynesia,
        '689'
    ],
    [
        'Gabon',
        CountryISO.Gabon,
        '241'
    ],
    [
        'Gambia',
        CountryISO.Gambia,
        '220'
    ],
    [
        'Georgia',
        CountryISO.Georgia,
        '995'
    ],
    [
        'Germany',
        CountryISO.Germany,
        '49'
    ],
    [
        'Ghana',
        CountryISO.Ghana,
        '233'
    ],
    [
        'Gibraltar',
        CountryISO.Gibraltar,
        '350'
    ],
    [
        'Greece',
        CountryISO.Greece,
        '30'
    ],
    [
        'Greenland',
        CountryISO.Greenland,
        '299'
    ],
    [
        'Grenada',
        CountryISO.Grenada,
        '1473'
    ],
    [
        'Guadeloupe',
        CountryISO.Guadeloupe,
        '590',
        0
    ],
    [
        'Guam',
        'gu',
        '1',
        1,
        [
            '671',
        ]
    ],
    [
        'Guatemala',
        CountryISO.Guatemala,
        '502'
    ],
    [
        'Guernsey',
        CountryISO.Guernsey,
        '44',
        1,
        [1481]
    ],
    [
        'Guinea',
        CountryISO.Guinea,
        '224'
    ],
    [
        'Guinea-Bissau',
        CountryISO.GuineaBissau,
        '245'
    ],
    [
        'Guyana',
        CountryISO.Guyana,
        '592'
    ],
    [
        'Haiti',
        CountryISO.Haiti,
        '509'
    ],
    [
        'Honduras',
        CountryISO.Honduras,
        '504'
    ],
    [
        'Hong Kong',
        CountryISO.HongKong,
        '852'
    ],
    [
        'Hungary',
        CountryISO.Hungary,
        '36'
    ],
    [
        'Iceland',
        CountryISO.Iceland,
        '354'
    ],
    [
        'India',
        CountryISO.India,
        '91'
    ],
    [
        'Indonesia',
        CountryISO.Indonesia,
        '62'
    ],
    [
        'Iran',
        CountryISO.Iran,
        '98'
    ],
    [
        'Iraq',
        CountryISO.Iraq,
        '964'
    ],
    [
        'Ireland',
        CountryISO.Ireland,
        '353'
    ],
    [
        'Isle of Man',
        CountryISO.IsleOfMan,
        '44',
        2,
        [1624]
    ],
    [
        'Israel',
        CountryISO.Israel,
        '972'
    ],
    [
        'Italy',
        CountryISO.Italy,
        '39',
        0
    ],
    [
        'Jamaica',
        'jm',
        '1',
        1,
        [
            '876',
        ]
    ],
    [
        'Japan',
        CountryISO.Japan,
        '81'
    ],
    [
        'Jersey',
        CountryISO.Jersey,
        '44',
        3,
        [1534]
    ],
    [
        'Jordan',
        CountryISO.Jordan,
        '962'
    ],
    [
        'Kazakhstan',
        CountryISO.Kazakhstan,
        '7',
        1
    ],
    [
        'Kenya',
        CountryISO.Kenya,
        '254'
    ],
    [
        'Kiribati',
        CountryISO.Kiribati,
        '686'
    ],
    [
        'Kosovo',
        CountryISO.Kosovo,
        '383'
    ],
    [
        'Kuwait',
        CountryISO.Kuwait,
        '965'
    ],
    [
        'Kyrgyzstan',
        CountryISO.Kyrgyzstan,
        '996'
    ],
    [
        'Laos',
        CountryISO.Laos,
        '856'
    ],
    [
        'Latvia',
        CountryISO.Latvia,
        '371'
    ],
    [
        'Lebanon',
        CountryISO.Lebanon,
        '961'
    ],
    [
        'Lesotho',
        CountryISO.Lesotho,
        '266'
    ],
    [
        'Liberia',
        CountryISO.Liberia,
        '231'
    ],
    [
        'Libya',
        CountryISO.Libya,
        '218'
    ],
    [
        'Liechtenstein',
        CountryISO.Liechtenstein,
        '423'
    ],
    [
        'Lithuania',
        CountryISO.Lithuania,
        '370'
    ],
    [
        'Luxembourg',
        CountryISO.Luxembourg,
        '352'
    ],
    [
        'Macau',
        CountryISO.Macau,
        '853'
    ],
    [
        'Macedonia',
        CountryISO.Macedonia,
        '389'
    ],
    [
        'Madagascar',
        CountryISO.Madagascar,
        '261'
    ],
    [
        'Malawi',
        CountryISO.Malawi,
        '265'
    ],
    [
        'Malaysia',
        CountryISO.Malaysia,
        '60'
    ],
    [
        'Maldives',
        CountryISO.Maldives,
        '960'
    ],
    [
        'Mali',
        CountryISO.Mali,
        '223'
    ],
    [
        'Malta',
        CountryISO.Malta,
        '356'
    ],
    [
        'Marshall Islands',
        CountryISO.MarshallIslands,
        '692'
    ],
    [
        'Martinique',
        CountryISO.Martinique,
        '596'
    ],
    [
        'Mauritania',
        CountryISO.Mauritania,
        '222'
    ],
    [
        'Mauritius',
        CountryISO.Mauritius,
        '230'
    ],
    [
        'Mayotte',
        CountryISO.Mayotte,
        '262',
        1
    ],
    [
        'Mexico',
        CountryISO.Mexico,
        '52'
    ],
    [
        'Micronesia',
        CountryISO.Micronesia,
        '691'
    ],
    [
        'Moldova',
        CountryISO.Moldova,
        '373'
    ],
    [
        'Monaco',
        CountryISO.Monaco,
        '377'
    ],
    [
        'Mongolia',
        CountryISO.Mongolia,
        '976'
    ],
    [
        'Montenegro',
        CountryISO.Montenegro,
        '382'
    ],
    [
        'Montserrat',
        'ms',
        '1',
        1,
        [
            '664',
        ]
    ],
    [
        'Morocco',
        CountryISO.Morocco,
        '212',
        0
    ],
    [
        'Mozambique',
        CountryISO.Mozambique,
        '258'
    ],
    [
        'Myanmar',
        CountryISO.Myanmar,
        '95'
    ],
    [
        'Namibia',
        CountryISO.Namibia,
        '264'
    ],
    [
        'Nauru',
        CountryISO.Nauru,
        '674'
    ],
    [
        'Nepal',
        CountryISO.Nepal,
        '977'
    ],
    [
        'Netherlands',
        CountryISO.Netherlands,
        '31'
    ],
    [
        'New Caledonia',
        CountryISO.NewCaledonia,
        '687'
    ],
    [
        'New Zealand',
        CountryISO.NewZealand,
        '64'
    ],
    [
        'Nicaragua',
        CountryISO.Nicaragua,
        '505'
    ],
    [
        'Niger',
        CountryISO.Niger,
        '227'
    ],
    [
        'Nigeria',
        CountryISO.Nigeria,
        '234'
    ],
    [
        'Niue',
        CountryISO.Niue,
        '683'
    ],
    [
        'Norfolk Island',
        CountryISO.NorfolkIsland,
        '672'
    ],
    [
        'North Korea',
        CountryISO.NorthKorea,
        '850'
    ],
    [
        'Northern Mariana Islands',
        CountryISO.NorthernMarianaIslands,
        '1670'
    ],
    [
        'Norway',
        CountryISO.Norway,
        '47',
        0
    ],
    [
        'Oman',
        CountryISO.Oman,
        '968'
    ],
    [
        'Pakistan',
        CountryISO.Pakistan,
        '92'
    ],
    [
        'Palau',
        CountryISO.Palau,
        '680'
    ],
    [
        'Palestine',
        CountryISO.Palestine,
        '970'
    ],
    [
        'Panama',
        CountryISO.Panama,
        '507'
    ],
    [
        'Papua New Guinea',
        CountryISO.PapuaNewGuinea,
        '675'
    ],
    [
        'Paraguay',
        CountryISO.Paraguay,
        '595'
    ],
    [
        'Peru',
        CountryISO.Peru,
        '51'
    ],
    [
        'Philippines',
        CountryISO.Philippines,
        '63'
    ],
    [
        'Poland',
        CountryISO.Poland,
        '48'
    ],
    [
        'Portugal',
        CountryISO.Portugal,
        '351'
    ],
    [
        'Puerto Rico',
        CountryISO.PuertoRico,
        '1',
        3,
        ['787', '939']
    ],
    [
        'Qatar',
        CountryISO.Qatar,
        '974'
    ],
    [
        'Réunion',
        CountryISO.Réunion,
        '262',
        0
    ],
    [
        'Romania',
        CountryISO.Romania,
        '40'
    ],
    [
        'Russia',
        CountryISO.Russia,
        '7',
        0
    ],
    [
        'Rwanda',
        CountryISO.Rwanda,
        '250'
    ],
    [
        'Saint Barthélemy',
        CountryISO.SaintBarthélemy,
        '590',
        1
    ],
    [
        'Saint Helena',
        CountryISO.SaintHelena,
        '290'
    ],
    [
        'Saint Kitts and Nevis',
        CountryISO.SaintKittsAndNevis,
        '1869'
    ],
    [
        'Saint Lucia',
        'lc',
        '1',
        1,
        [
            '758',
        ]
    ],
    [
        'Saint Martin',
        CountryISO.SaintMartin,
        '590',
        2
    ],
    [
        'Saint Pierre and Miquelon',
        CountryISO.SaintPierreAndMiquelon,
        '508'
    ],
    [
        'Saint Vincent and the Grenadines',
        'vc',
        '1',
        1,
        [
            '784',
        ]
    ],
    [
        'Samoa',
        CountryISO.Samoa,
        '685'
    ],
    [
        'San Marino',
        CountryISO.SanMarino,
        '378'
    ],
    [
        'São Tomé and Príncipe',
        CountryISO.SãoToméAndPríncipe,
        '239'
    ],
    [
        'Saudi Arabia',
        CountryISO.SaudiArabia,
        '966'
    ],
    [
        'Senegal',
        CountryISO.Senegal,
        '221'
    ],
    [
        'Serbia',
        CountryISO.Serbia,
        '381'
    ],
    [
        'Seychelles',
        CountryISO.Seychelles,
        '248'
    ],
    [
        'Sierra Leone',
        CountryISO.SierraLeone,
        '232'
    ],
    [
        'Singapore',
        CountryISO.Singapore,
        '65'
    ],
    [
        'Sint Maarten',
        'sx',
        '1',
        1,
        [
            '721',
        ]
    ],
    [
        'Slovakia',
        CountryISO.Slovakia,
        '421'
    ],
    [
        'Slovenia',
        CountryISO.Slovenia,
        '386'
    ],
    [
        'Solomon Islands',
        CountryISO.SolomonIslands,
        '677'
    ],
    [
        'Somalia',
        CountryISO.Somalia,
        '252'
    ],
    [
        'South Africa',
        CountryISO.SouthAfrica,
        '27'
    ],
    [
        'South Korea',
        CountryISO.SouthKorea,
        '82'
    ],
    [
        'South Sudan',
        CountryISO.SouthSudan,
        '211'
    ],
    [
        'Spain',
        CountryISO.Spain,
        '34'
    ],
    [
        'Sri Lanka',
        CountryISO.SriLanka,
        '94'
    ],
    [
        'Sudan',
        CountryISO.Sudan,
        '249'
    ],
    [
        'Suriname',
        CountryISO.Suriname,
        '597'
    ],
    [
        'Svalbard and Jan Mayen',
        CountryISO.SvalbardAndJanMayen,
        '47',
        1
    ],
    [
        'Swaziland',
        CountryISO.Swaziland,
        '268'
    ],
    [
        'Sweden',
        CountryISO.Sweden,
        '46'
    ],
    [
        'Switzerland',
        CountryISO.Switzerland,
        '41'
    ],
    [
        'Syria',
        CountryISO.Syria,
        '963'
    ],
    [
        'Taiwan',
        CountryISO.Taiwan,
        '886'
    ],
    [
        'Tajikistan',
        CountryISO.Tajikistan,
        '992'
    ],
    [
        'Tanzania',
        CountryISO.Tanzania,
        '255'
    ],
    [
        'Thailand',
        CountryISO.Thailand,
        '66'
    ],
    [
        'Timor-Leste',
        CountryISO.TimorLeste,
        '670'
    ],
    [
        'Togo',
        CountryISO.Togo,
        '228'
    ],
    [
        'Tokelau',
        CountryISO.Tokelau,
        '690'
    ],
    [
        'Tonga',
        CountryISO.Tonga,
        '676'
    ],
    [
        'Trinidad and Tobago',
        'tt',
        '1',
        1,
        [
            '868',
        ]
    ],
    [
        'Tunisia',
        CountryISO.Tunisia,
        '216'
    ],
    [
        'Turkey',
        CountryISO.Turkey,
        '90'
    ],
    [
        'Turkmenistan',
        CountryISO.Turkmenistan,
        '993'
    ],
    [
        'Turks and Caicos Islands',
        CountryISO.TurksAndCaicosIslands,
        '1649'
    ],
    [
        'Tuvalu',
        CountryISO.Tuvalu,
        '688'
    ],
    [
        'U.S. Virgin Islands',
        'vi',
        '1',
        1,
        [
            '340',
        ]
    ],
    [
        'Uganda',
        CountryISO.Uganda,
        '256'
    ],
    [
        'Ukraine',
        CountryISO.Ukraine,
        '380'
    ],
    [
        'United Arab Emirates',
        CountryISO.UnitedArabEmirates,
        '971'
    ],
    [
        'United Kingdom',
        CountryISO.UnitedKingdom,
        '44',
        0
    ],
    [
        'United States',
        CountryISO.UnitedStates,
        '1',
        0
    ],
    [
        'Uruguay',
        CountryISO.Uruguay,
        '598'
    ],
    [
        'Uzbekistan',
        CountryISO.Uzbekistan,
        '998'
    ],
    [
        'Vanuatu',
        CountryISO.Vanuatu,
        '678'
    ],
    [
        'Vatican City',
        CountryISO.VaticanCity,
        '39',
        1
    ],
    [
        'Venezuela',
        CountryISO.Venezuela,
        '58'
    ],
    [
        'Vietnam',
        CountryISO.Vietnam,
        '84'
    ],
    [
        'Wallis and Futuna',
        CountryISO.WallisAndFutuna,
        '681'
    ],
    [
        'Western Sahara',
        CountryISO.WesternSahara,
        '212',
        1
    ],
    [
        'Yemen',
        CountryISO.Yemen,
        '967'
    ],
    [
        'Zambia',
        CountryISO.Zambia,
        '260'
    ],
    [
        'Zimbabwe',
        CountryISO.Zimbabwe,
        '263'
    ],
    [
        'Åland Islands',
        CountryISO.ÅlandIslands,
        '358',
        1
    ]
];