import { SDP_CONSTANTS } from '@app/core/helpers';
import { countries } from '@app/core/helpers/country';

interface Country {
  name: string;
  code: string;
}

export function createCountryList(sourceCountries: any[]) {
  let allCountries: Country[] = [];
  sourceCountries.forEach((c: any) => {
    const country = {
      name: c[0].toString(),
      code: c[1].toString(),
    };
    allCountries.push(country);
  });
  return allCountries;
}

const PersonalDetailStep = {
  firstName: {
    type: 'text',
    validations: {
      required: true,
    },
    errors: {
      required: 'manualRegistration.firstNameRequired',
    },
    placeholder: 'manualRegistration.firstName',
  },
  lastName: {
    type: 'text',
    validations: {
      required: true,
    },
    errors: {
      required: 'manualRegistration.lastNameRequired',
    },
    placeholder: 'manualRegistration.lastName',
  },
  dateOfBirth: {
    type: 'date',
    validations: {},
    errors: {},
    placeholder: 'manualRegistration.dateOfBirth',
  },
};

const AddressDetailStep = {
  addressLine1: {
    type: 'text',
    validations: {},
    errors: {},
    placeholder: 'manualRegistration.addressLine1',
  },
  addressLine2: {
    type: 'text',
    validations: {},
    errors: {},
    placeholder: 'manualRegistration.addressLine2',
  },
  address: {
    type: 'text',
    validations: {},
    errors: {},
    placeholder: 'manualRegistration.fullAddress',
    hidden: true,
  },
  townOrCity: {
    type: 'text',
    validations: {},
    errors: {},
    placeholder: 'manualRegistration.town',
  },
  country: {
    type: 'select',
    options: createCountryList(countries),
    validations: {},
    errors: {},
    placeholder: 'manualRegistration.country',
  },
  zipOrPostalCode: {
    type: 'text',
    validations: {},
    errors: {},
    placeholder: 'manualRegistration.postalCode',
  },
};

const ContactDetailStep = {
  emailAddress: {
    type: 'text',
    validations: {
      required: true,
      pattern: SDP_CONSTANTS.REGEX.EMAIL,
    },
    errors: {
      required: 'manualRegistration.emailAddressRequired',
      pattern: 'manualRegistration.emailAddressInvalid',
    },
    placeholder: 'manualRegistration.emailAddress',
    disabled: true,
  },
  telephoneNumber: {
    type: 'phone',
    validations: {
      required: true,
    },
    errors: {
      required: 'manualRegistration.telephoneRequired',
    },
    placeholder: 'manualRegistration.telephone',
  },
};

export const STEP_ITEMS = [
  { label: 'manualRegistration.step1', data: PersonalDetailStep },
  { label: 'manualRegistration.step2', data: AddressDetailStep },
  { label: 'manualRegistration.step3', data: ContactDetailStep },
  { label: 'manualRegistration.step4', data: {} },
];
