import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Renderer2
  
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SDP_CONSTANTS, Utils } from '@app/core/helpers';
import { HIDIdvaServiceCallbackEvent } from '@app/core/models';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import {
  LoaderService,
  HidAuthenticationService,
  OnboardingService,
  HidAuthDataService,
  HidIdvaService,
  BankingDataService,
  AuthService,
} from '@app/core/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { STEP_ITEMS, STEP_SINGLE } from './onboarding-validation-form';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SdpAlert } from '@app/core/helpers/sdp-alert';
import { Subscription } from 'rxjs';

import { HIDIdvaMessageResponse, HIDIdvaTransaction, HIDIdvaPollResponse, HIDIdvaActionCodes, HIDIdvaPii } from '@app/core/models/hid-idva-message.interface';
import { environment } from '@environments/environment';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input-gg';
import { formatDate } from '@angular/common';
import { Base } from '@app/core/base/base';
import { BaseForm } from '@app/core/base/base-form';

@Component({
  selector: 'app-component-onboarding-idv',
  templateUrl: './onboarding-idv.component.html',
  styleUrls: ['./onboarding-idv.component.scss'],
})

export class OnboardingIDVComponent
  extends BaseForm
  implements OnInit, OnDestroy
{
  @Output() flowtype = new EventEmitter<string>();
  @Output() readonly formSubmit = new EventEmitter<any>();
  public defaultaccountcode: string = environment.idv2defaccountCode;
  isMobile!: boolean;
  IDVPII!:HIDIdvaPii;
  IDVTransactions!: Array<HIDIdvaTransaction>;
  IDVResponse!: HIDIdvaPollResponse;
  requestID!: string;
  qrCodeData!: string;
  userEmail!: string;
  userInfo!: any;
  acknowledged: boolean = false;
  deviceOverride: boolean = false;
  pollInterval!: number;
  timer!:any;
  public optionsShow:boolean = false;
  public buttonName:any = 'Show RAW Response';
  gmappslink!:any;
  firstname!:any;
  lastname!:any;
  Fullname:string = '';
  formContent: any;
  activeStepIndex!: number;
  currentFormContent!: Array<any>;
  formData: any;
  formFields!: Array<Array<string>>;
  stepItems!: Array<any>;
  masterForm!: Array<FormGroup>;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  email: string;
  selectedCountry!: CountryISO;
  qrError!: string;
  maxDate = '';
  todayDate = new Date();
  allCountries: any;
  IDVStat!: number;
  deviceInfo!:any;
  progresstext: string = "Step 0 of 5";
  progressvaluenow: number = 0;
  idvonly: boolean = false;
  public inputAccountCode!: string;
  incorrectemail!: boolean;
  buttonhide: boolean = false;
  enableIframe: boolean = true;
  urlSafe!: SafeUrl;




  constructor( 
    private readonly fb: FormBuilder,
    private readonly loaderService: LoaderService,
    private readonly HidIdvaService: HidIdvaService,
    private readonly translateService: TranslateService,
    private readonly onBoardingService: OnboardingService,
    private readonly bankingDataService: BankingDataService,
    private readonly hidAuthDataService: HidAuthDataService,
    private readonly hidAuthService: HidAuthenticationService,
    private readonly authService: AuthService,
    public readonly deviceDetectorService: DeviceDetectorService,
    public sanitizer: DomSanitizer,
    private renderer: Renderer2
  ) {
    //Init State = IDVSTAT is 0
    //Init State = this.email came from previous state and is available through process
    super();
    this.IDVStat = Number(0);
    this.email = this.onBoardingService.email;    
    if (!(environment.module_auth)) { this.idvonly = true}

  }


  ngOnInit() {
    // Page is loading

    this.newIDVFlow()

    // Post Message Listener for iframe
    window.addEventListener(
      "message",
      (event) => {
        window.console.log('iframe-message')
        window.console.log(event)
        this._pollIDVStatus(this.requestID)
      },
      false,
    );
    
  }

  newIDVFlow(){
    this.maxDate = formatDate(this.todayDate, 'yyyy-MM-dd', 'en-US');
    this.progresstext="Step 1 of 5"
    this.progressvaluenow=0
    // if idvonly 

    // Let's Create Form For User Profile To Show Later
    this.IDVStat = Number(1)
    this.formContent = STEP_ITEMS;
   // this.formContent = STEP_SINGLE;
    this.activeStepIndex = 0;
    this.masterForm = [];
    this.currentFormContent = [];
    this.formFields = [];
    this.stepItems = this.formContent;
    this.stepItems.forEach((data, i) => {
      this.currentFormContent.push(this.stepItems[i]['data']); // holds name, validators, placeholder of all steps
      this.formFields.push(Object.keys(this.currentFormContent[i])); // holds string values for each field of all steps
      this.masterForm.push(this.buildForm(this.currentFormContent[i])); // holds all form groups
    });
    this.pollInterval = 15000
    this.progressvaluenow=10
    if (environment.module_auth){this._generateIDVLink()}
    this._initialDisclaimer()
    this.timer = null
  }
  
  setDeviceOverride(){
    this.deviceOverride = !this.deviceOverride 
  }

  // build separate FormGroups for each form
  buildForm(currentFormContent: any): FormGroup {
    const formDetails = Object.keys(currentFormContent).reduce(
      (obj: { [key: string]: [string, Validators] }, key) => {
        obj[key] = ['', this.getValidators(currentFormContent[key])];
        return obj;
      },
      {},
    );

    return this.fb.group(formDetails);
  }

  // get validator(s) for each field, if any
  getValidators(formField: any): Validators {
    return Object.keys(formField.validations).map((validator: string) => {
      const validatorFn = (Validators as { [key: string]: any })[validator];
      if (validator === 'required') {
        return validatorFn;
      } else {
        return validatorFn(formField.validations[validator]);
      }
    });
  }

  // get validation error messages per error, per field
  getValidationMessage(formIndex: number, formFieldName: string): string {
    const formErrors = this.masterForm[formIndex].get(formFieldName)?.errors;
    const errorMessages =
      this.currentFormContent[formIndex][formFieldName].errors;
    const validationError = errorMessages[Object.keys(formErrors as Object)[0]];

    return this.translateService.instant(validationError);
  }

  goToStep(step: string): void {
    this.activeStepIndex =
      step === 'prev' ? this.activeStepIndex - 1 : this.activeStepIndex + 1;

    this.setFormPreview();
  }

  setFormPreview(): void {
    this.formData = this.masterForm.reduce(
      (masterForm, currentForm) => ({ ...masterForm, ...currentForm.value }),
      {},
    );
    if (this.formData) {
      this.formData.address =
        this.formData.addressLine1 + ' ' + this.formData.addressLine2;
      if (this.formData.address === ' ') {
        this.formData.address = '';
      }

      if (this.formData.telephoneNumber) {
        this.formData.telephoneNumber.phoneNumber =
          '(' +
          this.formData.telephoneNumber.dialCode +
          ')' +
          ' ' +
          this.formData.telephoneNumber.number;
      }

      let country: [{ code: string }];

      if (this.formData.country !== '') {
        country = Object.assign([], this.allCountries).filter(
          (item: any) => item.name === this.formData.country,
        );
      } else {
        country = Object.assign([], this.allCountries).filter(
          (item: any) => item.name.indexOf('United States') > -1,
        );
      }

    

      this.masterForm[2].controls.emailAddress.setValue(this.email);
    }
  }

  onEnter(el: KeyboardEvent) {
    if (el.key === 'Enter') {
      (
        (el.currentTarget as HTMLFormElement).querySelector(
          'button.submit-btn',
        ) as HTMLButtonElement
      )?.click();
    }
    
  }

  trackByFn(index: number): number {
    return index;
  }

  editDetails(j: number) {
    this.activeStepIndex = j;
    this.setFormPreview();
  }

  onTelephoneNumberChange(contact: any) {
    if (contact) {
      this.masterForm[2].get('telephoneNumber')?.setErrors(null);
    } else {
      this.masterForm[2].get('telephoneNumber')?.setErrors({ required: true });
      this.getValidationMessage(2, 'telephoneNumber');
    }
  }
  
  private _initialDisclaimer() {
    this.loaderService.show();
      this.progressvaluenow=30
      this.IDVStat = Number(2)

    
    
    SdpAlert.fire({
      title: this.translateService.instant('IDV2.Disclaimer.title'),
      html: this.translateService.instant('IDV2.Disclaimer.text'),
      confirmButtonText: 'OK',
      showCancelButton: false,
    }).then(() => {this.loaderService.hide(), this.progresstext="Step 2 of 5";})
}



onEmailCheck(){
  this.IDVStat = Number(2)
  this.progressvaluenow = 40
  this.progresstext="Step 2 of 5"
}


  
public _IDVFlowStep2Confirm(){ 
  this.loaderService.show();
  this.buttonhide=true
  this._generateIDVLink(this.inputAccountCode)
 
}


  private _capitalizeName(fullnameIn:any){     
    let res = " "
    let fullname = fullnameIn.split(" ") 
    let rt = 0
    for (let names in fullname){
     
        if ( rt === 0) {
          this.firstname = fullname[names][0].toUpperCase() + fullname[names].substring(1,fullname[names].length).toLowerCase() 
        } else {
          this.lastname = fullname[names][0].toUpperCase() + fullname[names].substring(1,fullname[names].length).toLowerCase() + " "
        }
        if ( rt === (fullname.length - 1)) {
         this.lastname = this.lastname.substring(0, this.lastname.length - 1) 
        }
        rt = rt + 1 
        res = res + fullname[names][0].toUpperCase() + fullname[names].substring(1,fullname[names].length).toLowerCase() + " "
    }

    //set first and last name as well
   


return res.substring(1, res.length - 1)
}


private _processFail(){  
  this.progresstext="Failure to process, please try again. Preparing new attempt..."
  this.timer = ''
  setTimeout(()=>{   this.newIDVFlow() },2500)
}

  
  private _processSuccess(){
    this.loaderService.hide()
    this.progresstext="Step 4 of 5"
    this.progressvaluenow=80
     

    // this.IDVPII
    this.timer = ''
    this.IDVPII.FullName = this._capitalizeName(this.IDVPII.FullName)

    
   
    if( this.deviceInfo?.os == 'iOS')
      {
           this.gmappslink = "https://www.google.com/maps/@"+ this.IDVPII.Latitude + "," + this.IDVPII.Longitude + ",350m/data=!3m1!1e3?entry=ttu"
      } else { 
      this.gmappslink = "http://maps.google.com/maps?z=100&t=k&q=loc:"+ this.IDVPII.Latitude + "," + this.IDVPII.Longitude + ",+(IDV%20Verified)"
      }
      
 
    this.IDVPII.imageFront = "data:image/jpeg;base64," + this.IDVPII.imageFront
    this.IDVPII.imageSelfie = "data:image/jpeg;base64," + this.IDVPII.imageSelfie
    //this.formData.firstName = this.IDVPII.DQL_Final_FirstName_Result
    //this.formData.lastName = this.IDVPII.DQL_Final_Surname_Result
    //DATAMAPPING
   //this.formData.emailAddress = this.email
    //this.formData.dateOfBirth = this.IDVPII.DQL_Final_BirthDate_Result
    
    this.IDVStat = Number(4)
  }
  
  private _pollIDVStatus = (requestID:string) => {
     if (requestID){
      if (this.IDVStat == 3){     
        this.loaderService.hide();   
        this.HidIdvaService.statusByReq(this.requestID,
        (res:HIDIdvaPollResponse)=>{
            this.progresstext="Step 3 of 5"
            this.progressvaluenow=60
            this.IDVResponse = res
            if(this.IDVResponse.transactions[0].pii) {
              this.IDVPII = this.IDVResponse.transactions[0].pii
            
            }
            if(this.IDVResponse.requestStatus == 'SUCCESS') {
              this.loaderService.show();
              this.timer = null
              this.qrCodeData = ''
              this._processSuccess()
            }
            if(this.IDVResponse.requestStatus == 'FAILED') {
              this.loaderService.show();
              this.timer = null
              this.qrCodeData = ''
              this._processFail()
            }

        },
        (err:any)=>{window.console.log(err)})
      }
    }

  }

  onToggleSettings() {
    this.optionsShow = !this.optionsShow;

    // Change the name of the button.
    if(this.optionsShow)  
      this.buttonName = "Show RAW Response (x)";
    else
      this.buttonName = "Show RAW Response";
  }

  openNewTab() {
    window.open(this.qrCodeData);
  }


  _generateIDVLink(accountCode?:string){
    let codeGranted = (res:HIDIdvaMessageResponse) => {
        if (res.error) {  
          this.qrError = res.error
          this.IDVStat = Number(2)
          this.buttonhide = false
           } else { 

        

        this.qrCodeData = res.url
        this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.qrCodeData);
        this.requestID = res.requestID
        this.IDVStat = Number(3)
        this._pollIDVStatus(this.requestID)
      
        //if ((!this.deviceDetectorService.isMobile) && (!this.deviceOverride)) { 
          this.timer = setInterval(()=>{this._pollIDVStatus(this.requestID)},5000)
        //}
                       
        }
    }
    let fail = (res:any) => {

    }

    this.HidIdvaService.verify(
      environment.hostname + '/returnidp?IDVRet=1',
      (res:HIDIdvaMessageResponse) => codeGranted(res),
      (err:any) => fail(err),
      accountCode
    )    
  }

  private _createUser() {
    let userPayload = {
      emailAddress: this.email,
      telephoneNumber: {
        "number": "245 123 545",
        "internationalNumber": "+420 245 123 545",
        "nationalNumber": "245 123 545",
        "e164Number": "+420245123545",
        "countryCode": "CZ",
        "dialCode": "+420" 
      },
      addressLine1: '',
      town: '',
      country: '',
      postalCode: '',
    }
    
   
    this.hidAuthDataService
      .createUser(
        this.hidAuthService.createUserManualRegistrationPayload(userPayload),
      )
      .subscribe(
        (response: any) => {
          
          this.hidAuthService.userExternalId = response.externalId;
          this.hidAuthService.userInternalId = response.id;
          this._createPortalUser();
        },
        (error: HttpErrorResponse) => {
          if (error.status === 409 && error.error.errorCode === 1114) {
            this.error$$.next(this.translateService.instant('userExists'));
            this.loaderService.hide();
          }
        },
      );
  }

 

  private _createPortalUser() {
    const userInfo = {
      internalId: +this.hidAuthService.userInternalId,
      userId: this.hidAuthService.userExternalId,
      firstName: this.firstname || '',
      lastName: this.lastname || '',
      phoneno: {
        dialCode:  '',
        countryCode:  '',
        number:  '',
      },
      emailAddress: this.email,
     // dob: '1990-01-01',
      address: {
        addressLine1:  '-',
        town:  '-',
        country: '-',
        postalCode: '-',
      }
    };

    this.bankingDataService
      .addUser(this.hidAuthService.userExternalId, userInfo)
      .subscribe(
        () => this._registerAuthenticator(),
        (error: HttpErrorResponse) => {
          if (error.status === 409) {
            this.bankingDataService
              .deleteUser(this.hidAuthService.userExternalId)
              .subscribe(() => this._createPortalUser());
          }
        },
      );
  }

  private _registerAuthenticator() {
    this.hidAuthDataService
      .registerAuthenticator(
        this.hidAuthService.getRegisterOOBEMLAuthenticatorPayload(
          Utils.generateRandomString(),
        ),
      )
      .subscribe((response: any) => {
        this._sendOOBEmail(response.activationCode);
      });
  }

  private _sendOOBEmail(activationCode: string) {
    this.progresstext="Step 5 of 5"
    this.progressvaluenow=100
    this.hidAuthDataService
      .sendOOBEmail(
        this.hidAuthService.getSendOOBEmailPayload(
          this.hidAuthService.userExternalId,
          activationCode,
        ),
      )
      .subscribe((response: any) => {
        if (response.context.AUTHENTICATION_OOB_SENT) {
          this.loaderService.hide();
          this.flowtype.emit(SDP_CONSTANTS.FLOW_TYPES.TEMPORARY_PASSWORD);
          this.viewchange.emit(SDP_CONSTANTS.VIEWS.FLOW_END);
        }
      });
  }

  onSubmitClick(): void {
    this.submit();
    this.IDVStat = Number(5);
    this.progresstext="Step 5 of 5"
    this.progressvaluenow=99
    this.loaderService.hide();

    if (environment.module_auth){
      
    
      this.hidAuthDataService
        .authenticateClient()
        .subscribe((authClientResponse: any) => {
          this.hidAuthService.idToken = authClientResponse['access_token'];
          this.hidAuthDataService
            .authenticateUser()
            .subscribe((authUserResponse: any) => {
              this.hidAuthService.accessToken = authUserResponse['access_token'];
              this._createUser();
            });
        });
      }
      if (environment.module_idv2 && !(environment.module_auth)){
        this.loaderService.hide();
        this.viewchange.emit(SDP_CONSTANTS.VIEWS.FLOW_END);
      }
  }

  onCancel() {
    this.loaderService.hide();
    if (environment.module_auth) {
      this.HidIdvaService.terminate(this.requestID,
        () =>  {this.viewchange.emit(SDP_CONSTANTS.VIEWS.SEARCH_USER)},
        () =>  {this.viewchange.emit(SDP_CONSTANTS.VIEWS.SEARCH_USER)}   
        )
      } else {
    this.HidIdvaService.terminate(this.requestID,
      () =>  {this.viewchange.emit(SDP_CONSTANTS.VIEWS.FLOW_END)},
      () =>  {this.viewchange.emit(SDP_CONSTANTS.VIEWS.FLOW_END)}   
      )
    }
  }
   
    
  
 
  ngOnDestroy() {
      this.requestID = ''
      this.timer = null
  }
}
