<ng-container >
  <div class="sdp-onboarding-comp-container no-shadows">
    <app-loader></app-loader>

    <div class="sdp-onboarding-comp">
      <div class="sdp-onboarding-comp-header" >
        <div
        src=""
        class="majorbank-logo-blue brand-logo"
      
        ></div>
        <div class="sdp-onboarding-comp-header-title ml-12">
          {{ 'majorBankTitle' | translate }}
        </div>
      </div>
      <div>
        <ng-container *ngTemplateOutlet="chooseAuth"></ng-container>
      </div>
    </div>
  </div>
</ng-container>
<!-- <ng-container></ng-container> -->
<ng-template #chooseAuth>
  <div class="text-center">
    <div class="sdp-ob-form-header">
      <h3 class="sdp-ob-title">{{ 'chooseAuthentication.title' | translate }}</h3>
      <p class="sdp-ob-intro">
        {{ 'chooseAuthentication.description' | translate }}
      </p>
    </div>
  
    <div class="my-5 row">
      <ng-container *ngFor="let authType of authenticationTypes">
        <a
          [ngClass]="{
            activated: !authType.isDisabled,
            selected: authType.selected,
            'no-events': authType.isDisabled,
            'sdp-product-card-wrapper pointer col-sm-12 col-md-6 col-lg-6 col-xl-3': true
          }"
          appHoverImg
          [disabled]="authType.title !== authType.title"
          [noEvents]="authType.selected"
          (click)="onSubmit(authType.title)"
       
        >
          <div class="sdp-product">
            <img
              class="img-fluid"
              [src]="'./../../../../../assets/images/auth-type/' +
                authType.imageFileName +
                (authType.selected ? '-active' : '') +
                '.svg'
              "
              alt="{{ authType.title | translate }}"
            />
          </div>
          <div class="sdp-product-title">
            {{ authType.title | translate }}
          </div>
        </a>
      </ng-container>
    </div>
    <div class="form-group text-right">
      <ng-container *ngIf="cancelBtn">
        <button class="btn btn-primary-mb mr-3" (click)="onCancelClick()">
          {{ 'cancel' | translate }}
        </button>
      </ng-container>
      <!-- <button
        class="btn btn-primary-mb"
        (click)="onSubmit(authType.title)"
        [disabled]="!isAuthChecked"
      >
        {{ 'submit' | translate }}
      </button> -->
    </div>
  </div>
</ng-template>  
  