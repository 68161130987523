import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { STEP_ITEMS } from './manual-registration-form';
import { SDP_CONSTANTS, Utils } from '@app/core/helpers';
import { HidAuthenticationService } from '@app/core/services/hid-auth/hid-authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  LoaderService,
  OnboardingService,
  HidAuthDataService,
  BankingDataService,
} from '@app/core/services';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input-gg';
import { BaseForm } from '@app/core/base/base-form';



@Component({
  selector: 'mfav2-manual-registration',
  templateUrl: './manual-registration.component.html',
  styleUrls: ['./manual-registration.component.scss'],
})
export class MFAv2ManualRegistrationComponent
  extends BaseForm
  implements OnInit
{  
  @Input() locemail!: string;
  @Output() viewchange = new EventEmitter<string>();


  @Output() readonly formSubmit = new EventEmitter<any>();
  @Output() flowtype = new EventEmitter<string>();

  formContent: any;
  activeStepIndex!: number;
  currentFormContent!: Array<any>;
  formData: any;
  formFields!: Array<Array<string>>;
  stepItems!: Array<any>;
  masterForm!: Array<FormGroup>;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  email: string;
  selectedCountry!: CountryISO;
  
  maxDate = '';
  todayDate = new Date();
  allCountries: any;

  constructor(
    private readonly fb: FormBuilder,
    private readonly loaderService: LoaderService,
    private readonly translateService: TranslateService,
    private readonly onBoardingService: OnboardingService,
    private readonly bankingDataService: BankingDataService,
    private readonly hidAuthDataService: HidAuthDataService,
    private readonly hidAuthService: HidAuthenticationService,
  ) {
    super();
    this.email = this.locemail;
  }

  ngOnInit() {
    this.loaderService.hide();
    this.maxDate = formatDate(this.todayDate, 'yyyy-MM-dd', 'en-US');
    this.formContent = STEP_ITEMS;
    this.activeStepIndex = 0;
    this.masterForm = [];
    this.currentFormContent = [];
    this.formFields = [];
    this.stepItems = this.formContent;

    this.stepItems.forEach((data, i) => {
      this.currentFormContent.push(this.stepItems[i]['data']); // holds name, validators, placeholder of all steps
      this.formFields.push(Object.keys(this.currentFormContent[i])); // holds string values for each field of all steps
      this.masterForm.push(this.buildForm(this.currentFormContent[i])); // holds all form groups
    });

    this.allCountries = this.currentFormContent[1].country.options;
  }
  onCancel() {
    this.viewchange.emit('');
  }
  // build separate FormGroups for each form
  buildForm(currentFormContent: any): FormGroup {
    const formDetails = Object.keys(currentFormContent).reduce(
      (obj: { [key: string]: [string, Validators] }, key) => {
        obj[key] = ['', this.getValidators(currentFormContent[key])];
        return obj;
      },
      {},
    );

    return this.fb.group(formDetails);
  }

  // get validator(s) for each field, if any
  getValidators(formField: any): Validators {
    return Object.keys(formField.validations).map((validator: string) => {
      const validatorFn = (Validators as { [key: string]: any })[validator];
      if (validator === 'required') {
        return validatorFn;
      } else {
        return validatorFn(formField.validations[validator]);
      }
    });
  }

  // get validation error messages per error, per field
  getValidationMessage(formIndex: number, formFieldName: string): string {
    const formErrors = this.masterForm[formIndex].get(formFieldName)?.errors;
    const errorMessages =
      this.currentFormContent[formIndex][formFieldName].errors;
    const validationError = errorMessages[Object.keys(formErrors as Object)[0]];

    return this.translateService.instant(validationError);
  }

  goToStep(step: string): void {
    this.activeStepIndex =
      step === 'prev' ? this.activeStepIndex - 1 : this.activeStepIndex + 1;

    this.setFormPreview();
    this.email = this.onBoardingService.email;
  }

  setFormPreview(): void {
    this.formData = this.masterForm.reduce(
      (masterForm, currentForm) => ({ ...masterForm, ...currentForm.value }),
      {},
    );
    if (this.formData) {
      this.formData.address =
        this.formData.addressLine1 + ' ' + this.formData.addressLine2;
      if (this.formData.address === ' ') {
        this.formData.address = '';
      }

      if (this.formData.telephoneNumber) {
        this.formData.telephoneNumber.phoneNumber =
          '(' +
          this.formData.telephoneNumber.dialCode +
          ')' +
          ' ' +
          this.formData.telephoneNumber.number;
      }

      let country: [{ code: string }];

      if (this.formData.country !== '') {
        country = Object.assign([], this.allCountries).filter(
          (item: any) => item.name === this.formData.country,
        );
      } else {
        country = Object.assign([], this.allCountries).filter(
          (item: any) => item.name.indexOf('United States') > -1,
        );
      }

    

      this.masterForm[2].controls.emailAddress.setValue(this.email);
    }
  }

  onEnter(el: KeyboardEvent) {
    if (el.key === 'Enter') {
      (
        (el.currentTarget as HTMLFormElement).querySelector(
          'button.submit-btn',
        ) as HTMLButtonElement
      )?.click();
    }
  }

  trackByFn(index: number): number {
    return index;
  }

  editDetails(j: number) {
    this.activeStepIndex = j;
    this.setFormPreview();
  }

  onTelephoneNumberChange(contact: any) {
    if (contact) {
      this.masterForm[2].get('telephoneNumber')?.setErrors(null);
    } else {
      this.masterForm[2].get('telephoneNumber')?.setErrors({ required: true });
      this.getValidationMessage(2, 'telephoneNumber');
    }
  }

  onSubmitClick(): void {
    this.submit();
    this.loaderService.show();
    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.hidAuthService.idToken = authClientResponse['access_token'];
        this.hidAuthDataService
          .authenticateUser()
          .subscribe((authUserResponse: any) => {
            this.hidAuthService.accessToken = authUserResponse['access_token'];
            this._createUser(this.formData);
          });
      });
  }

  private _createUser(userPayload: any) {
    this.hidAuthDataService
      .createUser(
        this.hidAuthService.createUserManualRegistrationPayload(userPayload),
      )
      .subscribe(
        (response: any) => {
          this.hidAuthService.userExternalId = response.externalId;
          this.hidAuthService.userInternalId = response.id;
          this._createPortalUser();
        },
        (error: HttpErrorResponse) => {
          if (error.status === 409 && error.error.errorCode === 1114) {
            this.error$$.next(this.translateService.instant('userExists'));
            this.loaderService.hide();
          }
        },
      );
  }

  private _createPortalUser() {
    const userInfo = {
      internalId: +this.hidAuthService.userInternalId,
      userId: this.hidAuthService.userExternalId,
      firstName: this.formData.firstName,
      lastName: this.formData.lastName,
      phoneno: {
        dialCode: this.formData.telephoneNumber.dialCode,
        countryCode: this.formData.telephoneNumber.countryCode,
        number: this.formData.telephoneNumber.number,
      },
      emailAddress: this.formData.emailAddress,
//dob: this.formData.dateOfBirth,
      address: {
        addressLine1: this.formData.addressLine1.replace('/',''),
        addressLine2: this.formData.addressLine2.replace('/',''),
        town: this.formData.townOrCity,
        country: this.formData.country,
        postalCode: this.formData.zipOrPostalCode,
      },
    };

    this.bankingDataService
      .addUser(this.hidAuthService.userExternalId, userInfo)
      .subscribe(
        () => this._registerAuthenticator(),
        (error: HttpErrorResponse) => {
          if (error.status === 409) {
            this.bankingDataService
              .deleteUser(this.hidAuthService.userExternalId)
              .subscribe(() => this._createPortalUser());
          }
        },
      );
  }

  private _registerAuthenticator() {
    this.hidAuthDataService
      .registerAuthenticator(
        this.hidAuthService.getRegisterOOBEMLAuthenticatorPayload(
          Utils.generateRandomString(),
        ),
      )
      .subscribe((response: any) => {
        this._sendOOBEmail(response.activationCode);
      });
  }

  private _sendOOBEmail(activationCode: string) {
    this.hidAuthDataService
      .sendOOBEmail(
        this.hidAuthService.getSendOOBEmailPayload(
          this.hidAuthService.userExternalId,
          activationCode,
        ),
      )
      .subscribe((response: any) => {
        if (response.context.AUTHENTICATION_OOB_SENT) {
          this.loaderService.hide();
          this.flowtype.emit(SDP_CONSTANTS.FLOW_TYPES.TEMPORARY_PASSWORD);
          this.viewchange.emit(SDP_CONSTANTS.MFAV2_USECASES.FLOW_END);
        }
      });
  }
}
