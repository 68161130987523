import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { setContext } from '@app/core/interceptors/api/api.interceptor';
import { Router } from '@angular/router';
import { SDP_CONSTANTS } from '@app/core/helpers';
import { SessionUser } from '@app/core/models/session-user';
import { VisitTag } from '@app/core/models/visit-tag';

import { BehaviorSubject } from 'rxjs';
import { HidAuthenticationService } from '../hid-auth/hid-authentication.service';
import * as uuid from 'uuid';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // Holds logged-in user info
  // Enrich User Info
  // Use as facility across service, e.g. via onboarding
  // Align Model across hid-authentication.service, banking.service and therfore views


  // Enrich User Info
  // Object maker and serializer for HID Auth Service Attribute Storage
  // Ditto for IDV

  private readonly currentUser$$ = new BehaviorSubject<SessionUser>(
    {} as SessionUser,
  );
  private readonly visitTag$$ = new BehaviorSubject<VisitTag>(
    {} as VisitTag,
  );


  
  constructor(
    private readonly router: Router,
    private readonly injector: Injector,
    private readonly http: HttpClient,
  ) {}
   



  getVisit():VisitTag {
    let Tag = {tttt: ''}
    if (!sessionStorage.getItem(SDP_CONSTANTS.VISIT_TAG)) {
      Tag.tttt = uuid.v4()
      
      sessionStorage.setItem(SDP_CONSTANTS.VISIT_TAG, JSON.stringify(Tag))
    }
    let exTag = sessionStorage.getItem(SDP_CONSTANTS.VISIT_TAG)
    if (!exTag) {
      Tag.tttt = uuid.v4()
      
      sessionStorage.setItem(SDP_CONSTANTS.VISIT_TAG, JSON.stringify(Tag))
    } else {
      Tag = JSON.parse(exTag)
    }
    this.visitTag$$.next(Tag)
    
    return this.visitTag$$.value

  } 
   
  /**
   * To get the logged-in user
   * @returns SessionUser
   */
  getCurrentUser(): SessionUser {
    return this.currentUser$$.value;
  }

  /**
   * Checks if the user present in current session and it's a valid user.
   * @returns boolean
   */
  isSessionAlive(): boolean {
    const sessionUser = sessionStorage.getItem(SDP_CONSTANTS.SESSION_USER);

    if (!sessionUser) {
      return false;
    }

    const user = JSON.parse(sessionUser) as SessionUser;

    const alive = !!user && !!user.userExternalId && !!user.userInternalId;

    if (!alive) {
      this.invalidateSession();
    }

    // Checking for user info post browser refresh
    if (alive && !Object.entries(this.getCurrentUser()).length) {
      // Required to update the currentUser value
      this.login(user);

      // Required to update the user in hid-authentication.service.ts
      this.injector
        .get(HidAuthenticationService)
        .searchUser(user.userExternalId, (response: any) =>
          this.injector.get(HidAuthenticationService).setUser(response),
        );
    }

    return alive;
  }

  /**
   * Login a user.
   * @param user: SessionUser
   * @description Adds the user in sessionStorage and updates the currentUser value.
   */
  login(user: SessionUser) {
    sessionStorage.setItem(SDP_CONSTANTS.SESSION_USER, JSON.stringify(user));
    this.currentUser$$.next(user);
  }

  /**
   * Logout current user.
   * @description Invalidate the session (refer invalidationSession()) and redirects user to the portal home page.
   */
  logout() {
    this.invalidateSession();
    this.router.navigateByUrl(SDP_CONSTANTS.ROUTES.HOME);
  }

  /**
   * Invalidate current user's session
   * @description Removes the current user object from sessionStorage
   * and update the currentUser value to EMPTY SessionUser object.
   */
  invalidateSession() {
    sessionStorage.removeItem(SDP_CONSTANTS.SESSION_USER);
    this.currentUser$$.next({} as SessionUser);
  }
}
