import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SDP_CONSTANTS } from '@app/core/helpers';
import { setContext } from '@app/core/interceptors/api/api.interceptor';
import { Profile } from '@app/core/models';
import { HIDDeeplink , HIDDeeplinkStore,  HIDDeeplinkUserStore, HIDgenerateDeeplinkResponse,HIDMonitorDeeplinkResponse} from '@app/core/models/hid-hw-message.interface';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})

export class HidHwDataService {
  constructor(
    private readonly http: HttpClient
  ) {}




  monitorDeeplinks(): Observable<HIDMonitorDeeplinkResponse>  {
    return this.http.get<HIDMonitorDeeplinkResponse>('monitor-deeplink', {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_HW_API,    
      }),
    });
  }

  generateDeeplink(    
    action: string,
    userid:string,
    tdsData?: string,
  ): Observable<HIDgenerateDeeplinkResponse> {
    return this.http.post<HIDgenerateDeeplinkResponse>(
      'generate-deeplink',
     { 
      action: action,
      userid: userid,
      tdsData:tdsData
      },
      {       
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_IDVA_API,        
        }),
      },
    );
  }


  removeDeeplink(    
    ref:string   
  ): Observable<string> {
    return this.http.post<string>(
      'remove-deeplink',
     { 
      ref: ref     
      },
      {       
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_IDVA_API,        
        }),
      },
    );
  }
  




}
