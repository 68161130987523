<div [ngClass]='makeClass("sdp-","-bg")'>

  <div class="w-100" >

   

    <div class="page-column d-flex flex-column"> 
            <div [ngClass]="(theme=='idv') ? 'w-100 product-banner' : 'w-100 product-banner'  ">

              <ng-container *ngIf="(theme=='idv')">

                <video playsinline="" autoplay="" muted="" loop="" 
                data-src="./../../../assets/images/idv-banner.mp4" data-observed=""
                 src="./../../../assets/images/idv-banner.mp4"
                 >
                </video>
              </ng-container>
                
             




              <div class="sdp-product-header mt-4 mx-auto"> 


                
                  <div class="container sdp-product-text">
                    <h1 class="sdp-products-intro-heading">
                      {{ 'productComponent.title1' | translate }}  <b> {{ 'productComponent.title2' | translate }}&#174; {{ 'productComponent.title3' | translate }} </b> demonstration.               
                        </h1>
                        <p class="sdp-products-intro-desc">
                          {{ 'productComponent.description' | translate }}
                        </p>
                  </div>               
         
      


                


        


          <ng-container *ngFor="let product of products">
            <div class="sdp-product-linkcard d-flex align-items-center justify-content-center" *ngIf="(product.enabled)">

              <a *ngIf="(product.idplink == 2)"
              class="                
              w-100 
              btn btn-secondary btn-lg 
              d-flex align-items-center justify-content-center
              "
              [href]="" 
                       
              style="height: 100%;"
              (click)="
                onProductClick(product, $event)
              "
              >
               
              <span>{{ product.title | translate }}</span>
            </a>


            <a *ngIf="(product.idplink == 1)"
            class="                
            w-100 
            btn btn-primary-mb btn-lg 
            d-flex align-items-center justify-content-center
            "
            [href]="idplink" 
                     
            style="height: 100%;"
            (click)="
              onProductClick(product, $event)
            "
            >
             
            <span>{{ product.title | translate }}</span>
          </a>

        
       
          <a *ngIf="(product.idplink == 0)"
          class="                
          w-100 
          btn btn-secondary btn-lg 
          d-flex align-items-center justify-content-center
          "
                  [href]="product.redirectLink"
                  style="height: 100%;"
                  (click)="
                    onProductClick(product, $event)
                  "
                  >
                      
                  <div class=>{{ product.title | translate }}</div>
        </a>
      </div>
          </ng-container>
      
          
    </div>
  </div>

   
      <div class="sdp-blog-articles mx-auto">
        











     
      <div class="spacer-line w-100 mx-auto" style="height:25px; background-color:#ffffff3f"></div>
      <ng-container *ngFor="let article of items">
          <a    
            target="_blank"         
            [href]="article.link"    
            class="mx-auto sdp-ul-no-underline article-text"
            >

            <div class="d-md-flex align-items-center justify-content-center sdp-article-box sdp-article-box">
              <div style="padding:2% 2% 2% 2%;"  class="sdp-article-image">
                <img 
                  class="img-fluid article-image"
                  src="{{ article.enclosure.url }}"
                  alt="{{ article.title }}"
                /></div>
              <div style="padding:2% 2% 2% 2%;" class="sdp-article-content">
              <small>{{ article.pubDate.slice(0,-15)  }}</small>
              <h4>{{ article.title }}</h4>
              
              <p>{{ article.contentSnippet }} </p>            
              <small>{{ article.creator }}</small>
            </div>
          </div>
          </a>
          <div class="spacer-line w-100 mx-auto" style="height:10px; background-color:#ffffffbf"></div>
    
      </ng-container>



        <div _ngcontent-ng-c2874729460="" 
         class="d-md-flex align-items-center justify-content-center
         more-text ">
         <a class="more-text-color" href="https://blog.hidglobal.com">
          Would you like to know more?
        </a>
      </div>

        <div class="spacer-line w-100 mx-auto" style="height:25px; background-color:#ffffff3f"></div>
        <br />
        





     
    </div>
  </div>
</div>
