import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SDP_CONSTANTS } from '@core/helpers';
import { HIDArtsArticle } from '@app/core/models/hid-arts-article';
import { environment } from '@environments/environment';
import { 
  AuthService,
  SharedService,
  HidRmsService,
  BlogDataService
} from '@core/services';



interface IProduct {
  title: string;
  imageFileName: string;
  redirectLink?: string;
  routerLink?: string;
  idvlink?: boolean;
  idv2link?: boolean;
  enabled?: boolean;
  idplink?: number;
}



@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})

export class ProductsComponent {  


  idplink: string = ''
  theme: string = environment.theme ;
  products: IProduct[] = [];
  idvonly:boolean = false;
 
  items?: Array<HIDArtsArticle>

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly blogDataService:BlogDataService,
  
  ) {
    
  }
  ngOnInit() {
  
    if (environment.module_auth && !(environment.module_hw)){
      this.products = [
        {
         title: "Use SaaS Identity Portal",
         imageFileName: '',
         redirectLink: this.idplink,
         enabled: true,
         idplink:1
       },   
       {
        title: "Login Here",
        imageFileName: '',
        routerLink: SDP_CONSTANTS.ROUTES.BANKING,
        enabled: true,
        idplink:0    
      },   
     ]
      } 
    if (environment.module_idv2 && !(environment.module_auth)){
       this.idvonly = true 
        this.products = [
          {
            title: "To start HID IDV solution, click here.",
            imageFileName: '',    
            enabled: true,
            idv2link: true,
            idplink:2    
          },      
       ]
        }
    if (environment.module_auth && environment.module_hw && (this.theme == 'majorcompany') ){
      this.products = [
        {
         title: "Test Page",
         imageFileName: '',
         routerLink: SDP_CONSTANTS.ROUTES.TESTPAGE,
         enabled: true,
         idplink:1
       },   
       {
        title: "Login Here",
        imageFileName: '',
        routerLink: SDP_CONSTANTS.ROUTES.BANKING,
        enabled: true,
        idplink:0    
      },   
    ]
    }

    this._getArticles();
    this._addScripts(); 
    setTimeout(()=>this._getArticles(),1000)
    this._generateIDPLink();
    
    
  }
  ngOnDestroy() {

    
    
  }

public makeClass(before:string,after:string){
  return before + this.theme + after
}


 private _addScripts() {
  
  
 }




private _getArticles(){
    return this.blogDataService
          .getArticles()
          .subscribe( (res: any) => { 
          
      
            this.items = res.items
          
          
          
            
            return this.items
            } )
  }
      




 private _generateIDPLink(){
    let client_id = environment.hidAuth.clientId
    let state = this.authService.getVisit()?.tttt
    let nonce = this.authService.getVisit()?.tttt
    let scope = 'openid'
    let response_type= "code id_token"
    let env = environment.hidAuth.serviceUrl
    let ten = environment.hidAuth.tenantId
    let IDPbase = environment.hidAuth.IDPbase
    let redirect_uri = environment.hidAuth.redirect_uri      

    // this.idplink = 'https://' +  env + '/idp/' + ten + '/authn/login?' + 
    this.idplink =  '' + IDPbase +
                      '?state=' + state + '&'  + 
                      'scope=' + scope + '&' +
                      'nonce=' + nonce + '&' +
                      'response_type=' + response_type + '&' +
                      'client_id=' + client_id + '&' +
                      'redirect_uri=' + redirect_uri // + "&"  +
                      //'acr_values=' + acr
    
    

 }

  onProductClick(product: IProduct, event: Event) {
    if (product.routerLink) {
      event.preventDefault();
      this.router.navigate([`/${product.routerLink}`], {
        relativeTo: this.activatedRoute,
      });
    } 
    if (product.redirectLink){  this.router.navigate([`${this.idplink }`])  }
    if (product.idv2link) {  
      this.router.navigateByUrl(`/${ SDP_CONSTANTS.ROUTES.SECURITY}`, {
        state: {
          initView: SDP_CONSTANTS.VIEWS.FLOW_END,
          idvOnly: true,
        }   
      })
    }
    if (product.idvlink) {
      this.router.navigateByUrl(`/${SDP_CONSTANTS.ROUTES.ONBOARDING}`, {
        state: {
          initView: SDP_CONSTANTS.VIEWS.FLOW_END,
          idvOnly: true,
        } 
      })
    }
  }
}

