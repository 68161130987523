// Embed in Shared Service
// Make it inject logo/colors 
// Make it sensitive to environment and change colors/logo if needed
// Force cache invalidation/full refresh by client from here.



import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { setContext } from '@app/core/interceptors/api/api.interceptor';
import { SDP_CONSTANTS } from '@app/core/helpers';

@Injectable({
  providedIn: 'root',
})
export class SystemService {


  // 1st Function -> Check for backend liveness, if fail then return alert. 
  // Keep checking -> Remove alert when backend lives

  public aliveness: boolean = false;
  private checker!: {};
  constructor(  private readonly http: HttpClient, )
   { 
    this._checkBackend()
    this.checker = setInterval(()=>{ this._checkBackend(); },20000)
   }
   


  public isAlive(){   
    return this.aliveness
  }




  private _checkBackend(){
    
    //BackendCheck
    return this._getLive()         
               .subscribe( (res: any) => { if (res.majorbankalive == "OK") { this.aliveness=true;  }                  
                         },(err:any) => { this.aliveness=false }
           )


            
  }

  private _getLive() {
    return this.http.get(`live`,{
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_ARTS_API
      }),
    });
  }
// 




}
