import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(public translate: TranslateService) {

    // if config says different theme, we use different theme.
    // construct-string
    // en-default
    // en-idv
    // en-majorcompany


    if ( environment.theme ) {
        if ( environment.theme == 'default'){ translate.setDefaultLang('en-default');}
        if ( environment.theme == 'idv'){ translate.setDefaultLang('en-idv');}
        if ( environment.theme == 'majorcompany'){ translate.setDefaultLang('en-majorcompany');}

    } else {
      translate.setDefaultLang('en-default');
    }




    
  }
}
