import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { SDP_CONSTANTS, Utils } from '@app/core/helpers';
import { FormBuilder, Validators } from '@angular/forms';
import { FundsTransfer } from '@app/core/models/portal/funds-transfer';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpErrorResponse } from '@angular/common/http';
import { SessionUser } from '@app/core/models/session-user';
import {
  SharedService,
  BankingDataService,
  HidAuthenticationService,
  HidRmsService,
  AuthService,
  HidDeviceDataService,
  LoaderService,
  ValidationService,
  OnboardingService,
} from '@app/core/services';
import { SdpAlert } from '@app/core/helpers/sdp-alert';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import * as uuid from 'uuid';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { HIDApproveClientApprovalStatus } from '@app/core/helpers/enums';
import {jwtDecode} from 'jwt-decode';
import { MFAv2Base } from './mfav2-base.component';
import { AuthenticatorTypes } from '@app/core/helpers/enums';
import { UserAttribute } from '@app/core/models';
import { AddDeviceComponent } from '@app/shared/components/add-device/add-device.component';





const enum EventTypes {
  LOGIN,
  FORGOT_PASSWORD,
}

const MFAv2Factors = {
  SMS: 'sms',
  APPROVE_PUSH: 'approvepush',
  APPROVE_CODE: 'approvecode',
  PASSWORD: 'password',
  ACTIVATIONEMAIL: 'activationemail',
}


const CONSTS = {
  FACTOR1:{
      USER_AUTHENTICATOR: 'urn:hid:scim:api:idp:2.0:UserAuthenticator',
  },
  CREATE_PASSWORD:{
      USER_ATTRIBUTE: 'urn:hid:scim:api:idp:2.0:UserAttribute',
      TITLE: 'TITLE',
      AUTHENTICATOR_PASSWORD:
        'urn:hid:scim:api:idp:2.0:policy:authenticator:Password',
      NOT_OLD_PASSWORD: 'notOldPassword',
      ACCESS_TOKEN: 'access_token',
  }
}


@Component({
  selector: 'mfav2',
  templateUrl: './mfav2.component.html',
  styleUrls: ['./mfav2.component.scss'],
})

 
// Uses Enivornment for if RMS is alive

export class MFAv2Component extends MFAv2Base implements OnInit {
  @Input() UseCase: string = SDP_CONSTANTS.MFAV2_USECASES.SEARCH_USER;  // bound to SDP_CONSTANTS.MFAV2_USECASES.MFAV2_USECASES
  @Input() loginAdaptive: boolean = false; // newly reused variable = denotes automatic FA
  @Input() login = false;
  @Input() deleteAccount = false;


// Legacy Check If It IS Done - Questionable Do sth better?
  @Input() showProcessing = false;
  @Input() set processingDone(processingDone: boolean) {
  if (processingDone !== undefined && processingDone && this.successScreen) {
    this.loaderService.hide();
    this.activeView = SDP_CONSTANTS.MFAV2_USECASES.FLOW_END;
    }
  }
  

  // Add Interface for Auth Object

  // Input Logic Setting
  @Input() authenticatorType!: AuthenticatorTypes;
  @Input() user!: any;
  @Input() useremail!: any;
  
  @Input () transactionSigning: boolean = false;
  @Input() successScreen = false; // Legacy Check If It IS Done 
  @Input() secureCodeDesc!: string;
  @Input() IDVVIEW: string = SDP_CONSTANTS.MFAV2_USECASES.DIGITAL_ONBOARDING;
  @Input() activeView!: string; 
  @Input() finalView!: string; 
  @Input() translationRequired = true;  // Legacy
  @Input() flowEndMessage!: string;
  @Input() pushMessage!: string; // Action Text - use unless payments
  @Input() fundsTransferInfo!: FundsTransfer; // data object for transfer - do not use push message
  @Input() trustedDevice!: boolean; // Legacy -  questionable logic, should autodetect, no idea why it is here

  // Input View Modifications - has defaults    
  @Input() viewFormat: string = 'embed';  // 	popup | embed | overlay | full   
  @Input() viewHeader: boolean = false;
  @Input() viewTopText: string = "";
  @Input() viewBottomText: string = ""

  @Input() viewSuccessScreen: boolean  = false;
  @Input() viewSuccessScreenMessage!: string;
  @Input() viewSuccessScreenActionBtnText!: string;

  @Input() viewBackBtn: boolean  = false;
  @Input() viewCancelBtn: boolean  = true; 



  @Output() authTypeChange = new EventEmitter<any>();
  @Output() hidtargetview = new EventEmitter<string>();
  @Output() flowend = new EventEmitter();
  @Output() rejected = new EventEmitter();
  @Output() cancelled = new EventEmitter();
  @Output() userfound = new EventEmitter<any>();
  @Output() adddeviceviewchange = new EventEmitter<string>();
  @Output() flowtype = new EventEmitter<string>();

  //Piping Out - Actions after X
  @Output() showhidapprovetargetview = new EventEmitter(); // Legacy - Delete?import { HIDApproveClientApprovalStatus } from '@app/core/helpers/enums';
  @Output() showsecuritytargetview = new EventEmitter(); // Legacy - Delete?import { HIDApproveClientApprovalStatus } from '@app/core/helpers/enums';
  @Output() fundstransfer = new EventEmitter();
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  @ViewChild('addDevice') addDeviceComponent!: AddDeviceComponent;

 
  newUser = false;
 
  firstFactor!: any;
  RMSfirstFactor!: any;

  addDeviceFlowView = SDP_CONSTANTS.MFAV2_USECASES.CHOOSE_AUTHENTICATION;

  FORM_FIELDS_LIB = {
    LOGIN:{
      USER_NAME: 'userName',
      PASSWORD: 'password',
    },
    SEARCH_USER:{
      EMAIL: 'email',
    },
    ONBOARDING: {
      REGISTRATION: 'registration',
    },
    CREATE_PASSWORD:{
      PASSWORD: 'passwordCreate',
      CONFIRM_PASSWORD: 'confirmPassword',
    }
  }

  hidApproveAuthRequestId!: string; // push notificatonid
  public qrShow:boolean = false;
  public buttonName:any = '';
  public QRResponse:boolean = false;
  QRTypeDialogue = false;
  qrCodeData!: string;
  fallbackButton: string =  SDP_CONSTANTS.ROUTES.BANKING;
  qrImplicit: boolean = true;

  timer!: any;
  timeout!: any; 
  timerCheck!: any;

  noDeviceFound = false;

  devices!: any[];
  idvenabled = environment.module_idv2;

  // Store everything about Payment
  paymentObject!: {
    payment_scoring: {
      scoring_result: {
        action: {},
        device: {},
        risk: number,
        payments: [{
          detections: [{}],
          payment_tags: [{}],
          score: number,
          standalone_signals: [{}]
          }],      
      },
      risk: number,
      session: {},
      tags: [{}],
      user: {},
      tm_action_id: string      
    },
    current_payment:{
      detections: [{}],
      payment_tags: [{}],
      score: number,
      standalone_signals: [{}]
    },
    appPaymentId: string;
  }

    idvOnly: boolean = false;
    isMobile!: boolean;
    addPayeeModalRef!: BsModalRef;
    fundsTransferModalRef!: BsModalRef;
    rmsCheck!: BsModalRef;
    payeeAccNoSub!: Subscription;
    payeeAmountSub!: Subscription;
    fromAccountSub!: Subscription;
    selectedPayeeUserId!: string;
    appPaymentId!: string;
    formResetValues!: string;
 // Actual password policy response received from HID auth service
 passwordPolicy!: any;

 // Contains the password guidelines used for display, and policy validation map
 policy!: { passwordGuidelines: string[]; policyMap: any };

 // Used for 'notUserAttribute' constraint validation
 userAttributes!: string[];
  
 // forgot-password-otp
 otp!: string;
 incorrectOtp!: boolean;

 fidoChallenge: string ='';
 fidoCredentialResponse: string ='';
 fidoCredOptions: string ='';

  constructor(
    public readonly deviceDetectorService: DeviceDetectorService,
    private readonly fb: FormBuilder,
    private readonly cd: ChangeDetectorRef,  
    private readonly loaderService: LoaderService,
    private readonly onBoardingService: OnboardingService,
    private readonly hidAuthService: HidAuthenticationService,
    private readonly hidDeviceDataService: HidDeviceDataService,
    private readonly translateService: TranslateService,
    private readonly hidRmsService:HidRmsService,
    private readonly authService:AuthService,
    private readonly sharedService:SharedService,
    private readonly bankingDataService:BankingDataService,
    private readonly router:Router,
  
  ) {
    super();  
  }

  ngOnInit(): void {     
    this.loaderService.show() // Show Loading
    this.isMobile = this.deviceDetectorService.isMobile() // Check if on phone

    if(environment.module_rms) {}    // Guard Init Module RMS
    if (environment.module_idv2){}   // Guard Init Module IDV    

    if(environment.module_auth) { 
      this.initMFAv2() // Main Start
    } // Guard Init Module Auth (Main Init if Hid Auth Service is enabled)

    if (environment.module_idv2 && !environment.module_auth) {
      //idvOnly
      this.idvOnly = true
      if (this.idvOnly) {
        this.flowEndMessage = "Thank you for trying HID IDV."
     
        this.activeView = this.IDVVIEW
        this.loaderService.show()
        this.onViewChange(this.IDVVIEW)   
        
      }
    } // Guard Init IDV Only Mode - Fallback - Face Auth Must not Use Onboarding-IDV
  } // Init Page

  initMFAv2(){
    //   this.loaderService.show() is on


    // window.console.log('mfav2component',[ 
    //   this.user, 
    //   this.useremail,
    //   this.onBoardingService.email, 
    //   this.authService.getCurrentUser(), 
    //   this.hidAuthService.user])
    // window.console.log('mfav2component',this.UseCase)
    // window.console.log('mfav2component',this.hidDeviceDataService.selectedDevice)


    switch(this.UseCase){     
      case SDP_CONSTANTS.MFAV2_USECASES.SEARCH_USER:{
        this._initForm(SDP_CONSTANTS.MFAV2_USECASES.SEARCH_USER)
        if (!this.isMobile) { this.S2A.onGenerateQRCode() } else { this.loaderService.hide() }
        this.login = true 
        this.showProcessing = true
        return;
        
      } //done     
      case SDP_CONSTANTS.MFAV2_USECASES.FACTOR1:{
        this._initForm(SDP_CONSTANTS.MFAV2_USECASES.FACTOR1);
        this.Factor1._performUserCheck()        
        return;
       
      }  //done
      case SDP_CONSTANTS.MFAV2_USECASES.ONBOARDING: {
        this._initForm(SDP_CONSTANTS.MFAV2_USECASES.ONBOARDING);        
        return;
      }  
      case SDP_CONSTANTS.MFAV2_USECASES.MANUAL_REGISTER: {
        this._initForm( SDP_CONSTANTS.MFAV2_USECASES.MANUAL_REGISTER);        
        return;
      }  
      case SDP_CONSTANTS.MFAV2_USECASES.CREATE_PASSWORD: {
        this.CreatePassword._setUserAttributes();
        this.hidAuthService.getPasswordGuidelines(
              this.CreatePassword._updatePasswordPolicy.bind(this),
         )
        this._initForm( SDP_CONSTANTS.MFAV2_USECASES.CREATE_PASSWORD);        
        return
      }         

      case SDP_CONSTANTS.MFAV2_USECASES.FACTOR2: {      
    
        return;
      }  

      case SDP_CONSTANTS.MFAV2_USECASES.HID_APPROVE_PUSH: {
        
              if(this.hidAuthService.userExternalId) { 
                this.hidAuthService.fetchUserDevices( 
                this.Factor2._fetchUserDevicesSuccess.bind(this),
                ) 
              }
            
            ; // Loads HID Authentication Service devices
    
        return;
      }  
      case SDP_CONSTANTS.MFAV2_USECASES.HID_APPROVE_CODE: {
      
        if(this.hidAuthService.userExternalId) { this.hidAuthService.fetchUserDevices( 
          this.Factor2._fetchUserDevicesSuccess.bind(this),
    )}; // Loads HID Authentication Service devices
    
        return;
      }  

      case SDP_CONSTANTS.MFAV2_USECASES.FORGOT_PASSWORD_OTP: {
        this.ForgotPassword.sendOTP()


        return;
      }
      case SDP_CONSTANTS.MFAV2_USECASES.ADD_DEVICE: {

        this.finalView = SDP_CONSTANTS.MFAV2_USECASES.LOGIN;
        return;
      }

      case SDP_CONSTANTS.MFAV2_USECASES.FLOW_END: {

        //also cleanup
        this.login = false
        
        return;
      }    


      default: {
        return;
      }
    }
  } // Init Auth Module

  private _initForm(which:string) {
  
    if (which){      
      if (which === SDP_CONSTANTS.MFAV2_USECASES.FACTOR1) {
        this.form = this.fb.group({
          [this.FORM_FIELDS_LIB.LOGIN.USER_NAME]: [
            '',
            [Validators.required, Validators.maxLength(100)],
          ],
          [this.FORM_FIELDS_LIB.LOGIN.PASSWORD]: ['', [Validators.required]],
        }); 
      }
      if (which === SDP_CONSTANTS.MFAV2_USECASES.SEARCH_USER) {
        this.form = this.fb.group({
          [this.FORM_FIELDS_LIB.SEARCH_USER.EMAIL]: [
            '',
            [
              Validators.required,
              Validators.maxLength(100),
              ValidationService.emailValidator,
            ],
          ],
        });
        
      }
      if (which === SDP_CONSTANTS.MFAV2_USECASES.CREATE_PASSWORD) {
        
          this.form = this.fb.group(
            {
              [this.FORM_FIELDS_LIB.CREATE_PASSWORD.PASSWORD]: ['', Validators.required],
              [this.FORM_FIELDS_LIB.CREATE_PASSWORD.CONFIRM_PASSWORD]: ['', Validators.required],
            },
            { validators: ValidationService.passwordValidator() },
          );
          this.loaderService.hide()
        
        
      }     
      if (which === SDP_CONSTANTS.MFAV2_USECASES.ONBOARDING) {
        this.form = this.fb.group({
          [this.FORM_FIELDS_LIB.ONBOARDING.REGISTRATION]: ['', Validators.required],
        });
        
      }

    }
  } // Init Input Forms

  get email() {
    return this.form.get(this.FORM_FIELDS_LIB.SEARCH_USER.EMAIL)?.value;
  } // Init Input Forms 
  get userName() {
    return this.form.get(this.FORM_FIELDS_LIB.LOGIN.USER_NAME)?.value;
  } // Init Input Forms
  get password() {
    return this.form.get(this.FORM_FIELDS_LIB.LOGIN.PASSWORD)?.value;
  } // Init Input Forms
  get registration() {
    return this.form.get(this.FORM_FIELDS_LIB.ONBOARDING.REGISTRATION)?.value;
  } // Init Input Forms
  get passwordCtrl() {
    return this.form.get(this.FORM_FIELDS_LIB.CREATE_PASSWORD.PASSWORD);
  }
  get passwordCreate() {
    return this.form.get(this.FORM_FIELDS_LIB.CREATE_PASSWORD.PASSWORD)?.value;
  }
  

  // LIBRARIES
  private _portalUserProcessor = {}  // Portal User Operations - To be Collected
  private _bankingPortalProcessor ={ }  // Payment functions - To be Collected 
  private _rmsProcessor = {   
      AddDevice: ()=>{
          this._rmsProcessor.ActionStep('approve', 'add-approve-device')
      },
      ActionStep: (action_properties:string, action_type:string)=>{
      if(environment.module_rms){
        let action =  {action_properties:action_properties, action_type:action_type}        
        this.hidRmsService.actionCreate(
          this.onBoardingService.email, 
          this.authService.getVisit()?.tttt,
          action,
          ()=>{} )
      } else { window.console.log('_rmsProcessor Matrix Error')}
      },
      ActionFinished: ()=>{
      if(environment.module_rms){

      } else { window.console.log('_rmsProcessor Matrix Error')}
      },
      TransactionGeneratePaymentObject:(amount:any, payee:any, countrycode:any, selectedPayeeUserId:any, message:any)=>{
       
        if(environment.module_rms){
          let sec = new Date().getSeconds()
          let due_date = new Date().toISOString().slice(0,10)
          let appPaymentId = due_date + "" + uuid.v4()
          
          this.paymentObject.appPaymentId = appPaymentId

          const payment:any = {
            "amount": Number(amount),
            "app_payment_id":  appPaymentId,
            "constant_symbol": sec+sec+3+'',
            "currency": "USD",
            "due_date": due_date,          
            "message_for_sender":"",
            "partner_account": {
              "app_account_id": selectedPayeeUserId +'',
              "country_bank_account": {
                "account_number": selectedPayeeUserId + '',
                "bank_code": "0999",
                "branch_code": "0999",
                "country": countrycode
              },
              // "iban": {
              //   "iban": "EU0999" + this.selectedPayeeUserId
              // },
              // "swift_account": {
              //   "account_number": this.selectedPayeeUserId + '',
              //   "swift": "EU0999PX"
              // },        
            },
            "payment_state": "open",
            "payment_type": "domestic",
            "priority": "standard",
            "source_account": {
              "app_account_id": payee?.payeeUserId,
              "country_bank_account": {
                "account_number":
                  payee?.accountNumber + '',
                "bank_code": "0999",
                "branch_code": "0999",
                "country": countrycode
              },
              // "iban": {
              //   "iban": "EU0999" +  payee?.accountNumber,
              // },
              // "swift_account": {
              //   "account_number":  payee?.accountNumber + '' ,
              //   "swift": "EU0999PX"
              // }        
            },
            "specific_symbol": sec+5+sec+3+ '',
            "trusted_beneficiary": true,   
            "variable_symbol": sec+50+sec+35 + '',
            "user_note": ""
          }
          if (message) {
            payment.user_note = message
            payment.message_for_sender= message
          }     
          return { payment: payment, appPaymentId: appPaymentId, due_date: due_date }
        } else { window.console.log('Matrix Error'); return { payment:null}}
      },
      TransactionCreate:(amount:any, payee:any, selectedPayeeUserId:any, message:any)=>{
        if (environment.module_rms) { 
          const nowu = this.hidAuthService.getUser()
          let countrycode = 'CZ'
          if (nowu.phoneno) { let ccdscd = nowu.phone; if (ccdscd.countryCode){ countrycode = ccdscd.countryCode} }
          let pArray = []  
          let pObje = this._rmsProcessor.TransactionGeneratePaymentObject(amount, payee, countrycode, selectedPayeeUserId, message)
          if ( pObje.payment = null ) {
             window.console.log('_rmsProcessor.TransactionGeneratePaymentObject Error'); return 
          } else {
            pArray.push(pObje.payment) 
         
      
            this.hidRmsService.paymentCreate(
              nowu.userName,
              this.authService.getVisit()?.tttt,
              pArray,        
              this._rmsProcessor.ReturnData.bind(this)
              )
          }
        } else { window.console.log('_rmsProcessor Matrix Error') }
          
            
      },
      TransactionComplete:(appPaymentId:string)=>{
        if (environment.module_rms) { 
          this.hidRmsService.paymentComplete(
            appPaymentId,
            "Closed_Accepted",
            this.authService.getVisit()?.tttt,
            this._rmsProcessor.ReturnData.bind(this)
         )
        } else { window.console.log('_rmsProcessor Matrix Error')}
      },
      TransactionReject:(appPaymentId:string)=>{
        if (environment.module_rms) { 
          this.hidRmsService.paymentComplete(
            appPaymentId,
            "Closed_Rejected",     
            this.authService.getVisit()?.tttt, 
            this._rmsProcessor.ReturnData.bind(this)
         )
        } else { window.console.log('_rmsProcessor Matrix Error')}
      },
      TransactionSign:(appPaymentId:string)=>{
        if (environment.module_rms) { 

  
    
        
        let sigdate = new Date().toISOString().slice(0,10)
        
    
        
        const nowu = this.hidAuthService.getUser()
        let tokenid = ''
        if (nowu.phoneno) { let ccdscd = nowu.phone; if (ccdscd.number){ tokenid = ccdscd.number} }
        tokenid = 'ApproveOTP' + tokenid
        this.hidRmsService.paymentSign(nowu.userName, this.authService.getVisit()?.tttt, appPaymentId, "open", "accepted" ,sigdate, tokenid, 'mtoken', ()=>{})
      
      } else { window.console.log('Matrix Error')}
      },
      PaymentRMSCheck:(
        successCB: (response: any) => void,
        stepUpCB: (resoponse:any)=> void, 
    )=>{
        if (environment.module_rms) { 
      
      

          this.paymentObject.payment_scoring = this.hidRmsService.getPaymentScore()
          if(this.paymentObject.payment_scoring) {
    
            let thres = 200      
            this.paymentObject.current_payment = this.paymentObject.payment_scoring.scoring_result.payments[0]
            window.console.log( this.paymentObject.current_payment )
          
    
    
            if (
                  (this.paymentObject.current_payment.score && this.paymentObject.current_payment.score <= thres )
              )
              {
                let message = 'Safe Session Detected.<br> Risk Score: '   + this.paymentObject.current_payment.score 
              
                
        
                setTimeout(() => {
                  SdpAlert.fire({
                    title: 'Success',
                    html: message,
                    icon: 'success',   
                    confirmButtonText: 'Continue',
                    showCancelButton: false,
                  }).then(result => {
                    if (result.isConfirmed) {        
                      successCB(result)
                    }});
                }, 100);
                
    
    
            } else {
              let message = 'Further Confirmation Needed. <br>'  +
              'Risk Score: ' + this.paymentObject.current_payment.score + '.<br>' 
              if (this.paymentObject.current_payment.standalone_signals.length > 0) {
                                 let detections = this.paymentObject.current_payment.standalone_signals.toString()    
    
    
    
                
                //message = message + 'Detections: ' +  detections
              }      
              this.loaderService.hide();
    
              setTimeout(() => {
                SdpAlert.fire({
                  title: 'Security Alert (Threat Prevention)',
                  html: message,
                  icon: 'warning',
                  confirmButtonText: 'Continue',
                  backdrop:true,
                  showCancelButton: false,
                }).then(result => {
                  if (result.isConfirmed) {        
                    stepUpCB(result)
                  }});
              }, 100);
      
            }
          }
        }
      },
      LoginStep:(
        accept:boolean,
        factorno:number,
        token:string,
        tokentype:string,
        successCB: () => void
      )=>{
        if (environment.module_rms) {        
          let accepted = 'invalid_credentials'
          if (accept) {accepted = 'step_accepted'}

          this.hidRmsService.loginStep(
            this.hidAuthService.getUser()?.externalId,
            factorno,
            accepted, 
            token,
            tokentype, 
            this.authService.getVisit()?.tttt,
            successCB
          )
        }

      },
      LoginFinished:()=>{
        if (environment.module_rms) {        
          this.hidRmsService.loginSuccess(
            this.hidAuthService.getUser()?.externalId,
            'authentication_scheme',
            this.authService.getVisit()?.tttt,
            ()=>{})
          }  
      },
      ReturnData:()=>{
        // Monitoring Mode - Logs
        window.console.log('RMS: Monitoring')
      },
      ReturnSuccess:()=>{
        // Blocking Mode Pass
      },
      ReturnFail:()=>{
        // Blocking Mode Fail
        this.rejected.emit();
        }
  }  // RMS integration - Mostly Done
  private _MFAEnforcer = {
    login:(

      pass:()=>{},
      fail:()=>{}

    )=>{



    },

  } // MFA Flows - More a concept than a function
  public S2A= {
    onToggleQRType:() => {
      this._clean() 
      this.qrImplicit = !this.qrImplicit
      if (!this.isMobile) { this.S2A.onGenerateQRCode() }
      
    },
    onToggleQRTypeDialogue:() => {  
      this.QRTypeDialogue = !this.QRTypeDialogue     
    },
    onGenerateQRCode:() => {
      this._ScanToApprove._qrCodeSignGenerate()
    }
  }  // Scan To Approve Public
  private _ScanToApprove = {
    _qrCodeSignGenerate:() => {
      let pushMessage = 'Please Login to MajorBank'
      let qrTypeString = 'accept | deny | report';
      if (this.qrImplicit) { qrTypeString = 'accept'}  
      let sendQrRequest = () => {
        this.hidAuthService.createQR(
          pushMessage,
          qrTypeString,
          _sendPushNotificationSuccess.bind(this),
        );
      }    
      let _sendPushNotificationSuccess = (response: any) => {
        this.hidApproveAuthRequestId = response['auth_req_id'];
        this.qrCodeData = response['txid']
        this.buttonName = "Login using QR Code";
        this.timerCheck = setInterval(()=> this._ScanToApprove._initiateQRCallbackCheck(),2000)
        
      }    
      sendQrRequest()
    },
    _initiateQRCallbackCheck: ()=> {
      let _qrCodeSignlogin = (uid:any, res1:any) => {
        let qremail = uid
        let process = () => {   
          this.hidAuthService.searchUser(
            qremail,
            _searchUserSuccess.bind(this),
          );
        }
      
        let _searchUserSuccess = (response: any) => {
          !!response.totalResults
            ? _onUserFound(response)
            : _onUserNotFound();
        }
      
        let _onUserNotFound = () => {
          setTimeout(() => {
             this.onViewChange(SDP_CONSTANTS.MFAV2_USECASES.ONBOARDING);        
          }, 100);
        }
      
        let _onUserFound = (response: any) => {
          this.bankingDataService.getUser(qremail).subscribe(
            () => {
             
              this.hidAuthService.setUser(response);            
              if (environment.module_rms) {      
                this._rmsProcessor.LoginStep(true, 0, this.hidAuthService.getUser()?.externalId, 'other', ()=>{} )                     
              }          
              this.userfound.emit(this.hidAuthService.getUser());
              _portalLogin(res1);
            },
            (error: HttpErrorResponse) => {
              if (error.status === 404) {
                _onUserNotFound();
              }
            },
          );
        }
        process()
      }
      let _portalLogin = (res:any) => {
        
          
        if (environment.module_rms) {      
          this._rmsProcessor.LoginStep(true, 1, this.hidAuthService.getUser()?.externalId, 'other', ()=>{} )                     
        }        
        if (
          (res as any).clientapprovalstatus ===
          HIDApproveClientApprovalStatus.ACCEPT
        ) {
          if (environment.module_rms) {  
            this._rmsProcessor.LoginStep(true, 2, this.hidAuthService.getUser()?.externalId, 'mtoken', this._loginToBank.bind(this))     
          } else {
            this._loginToBank()
          }
         
        } else {
          if (environment.module_rms) {
            this._rmsProcessor.LoginStep(false, 2, this.hidAuthService.getUser()?.externalId, 'mtoken',()=>this.rejected.emit())    
        } else {
           this.rejected.emit(); 
          }
        }     
      }    

      this.hidDeviceDataService.checkCallback(this.hidApproveAuthRequestId).subscribe((response: any) =>{
            if (response) {
              this.QRResponse = true
              this.hidDeviceDataService.doneCallback(this.hidApproveAuthRequestId).subscribe((response1: any) =>{
                this._clean()
                const res = jwtDecode(response['id_token']);
            
                _qrCodeSignlogin(res.sub, res)           
              })
            }
      })
    }
   
     
   

  }  // Scan To Approve Private  

  // VIEW Specific Methods
  public SearchUser(){
    let _searchUserSuccess = (response: any) => {
      !!response.totalResults
        ? _onUserFound(response)
        : _onUserNotFound();
    }


    let _onUserNotFound = () => {
   
      setTimeout(() => {
         this.onViewChange(SDP_CONSTANTS.MFAV2_USECASES.ONBOARDING);        
      }, 100);
    }
  
    let _onUserFound = (response: any) => {
      
      this.useremail = this.email
      this.bankingDataService.getUser(this.email).subscribe(
        () => {
         
          this.hidAuthService.setUser(response);            
          
          if (environment.module_rms) {      
            this._rmsProcessor.LoginStep(true, 0, this.hidAuthService.getUser()?.externalId, 'other', ()=>{} )                     
          }
          this.userfound.emit(this.hidAuthService.getUser());

          this.onViewChange(SDP_CONSTANTS.MFAV2_USECASES.FACTOR1);
        },
        (error: HttpErrorResponse) => {
          if (error.status === 404) {
            _onUserNotFound();
          }
        },
      );
    }
    this.submit();
    this.loaderService.show() 
    this.onBoardingService.email = this.email
    this.hidAuthService.searchUser(
      this.email,
      _searchUserSuccess.bind(this),
    );
  } // Search User - Input Email - View For MFAv2
  public Factor1 = {
    _performUserCheck: () => {
   
      this.loaderService.show()
      if (
        !this.hidAuthService.user ||
        !(
          this.hidAuthService.hasAuthenticator(
            undefined,
            AuthenticatorTypes.AT_OOBEML,
          )    
          &&
          this.hidAuthService.hasAuthenticator(
            undefined,
            AuthenticatorTypes.AT_STDPWD,
        ))
      ) {
        this.Factor1._searchUser();
      } else {
        this.user = this.hidAuthService.getUser();
        this.Factor1._updateUserName();
        this.Factor1._updateNewUserFlag();
        
      }
    },  
    _searchUser:() => { 
      this.hidAuthService.searchUser(
        this.onBoardingService.email,
        this.Factor1._searchUserSuccess.bind(this),
      );
    }  ,
    _searchUserSuccess: (response: any)  => {
      !!response.totalResults
        ? this.Factor1._onUserFound(response)
        : this.Factor1._onUserNotFound();
    },
   _onUserFound:(response: any)  => {
      this.hidAuthService.setUser(response);
      this.user = this.hidAuthService.getUser();
      this.userfound.emit(this.hidAuthService.getUser());
      this.Factor1._updateUserName();
      this.Factor1._updateNewUserFlag();
     
    },
   _onUserNotFound:()  => {
      this.loaderService.hide()
      this.Factor1._raiseUserNotFoundAlert();    
    },
   _updateUserName : ()  => {
      this.form
        .get(this.FORM_FIELDS_LIB.LOGIN.USER_NAME)
        ?.patchValue(this.hidAuthService.getUser().userName);
    },
   _updateNewUserFlag : ()   =>{  
  
     this.newUser = !this.hidAuthService.isRegisteredUser(); 
      if (!this.firstFactor) { if (!this.newUser){ 
        
        // this.Factor1._validatePUSH() 
        this.loaderService.hide()
      }} else {  
        this.loaderService.hide()  }
    },
   _raiseUserFoundAlert : () =>{
      SdpAlert.fire({
        title: this.translateService.instant('alert.userFound.title'),
        html: this.translateService.instant('alert.userFound.text'),
        confirmButtonText: 'OK',
        showCancelButton: false,
      });
    },
   _raiseUserNotFoundAlert : () => {
      setTimeout(  this.Factor1._switchToRegister.bind(this) , 500)
      SdpAlert.fire({
        title: this.translateService.instant('alert.userNotFound.title'),
        html: this.translateService.instant('alert.userNotFound.text'),
        confirmButtonText: 'OK',
        showCancelButton: false,     
      }).then(result => {
        if (result.isConfirmed) {        
          this.onViewChange(SDP_CONSTANTS.MFAV2_USECASES.ONBOARDING);
        }
      })
    },
    _switchToRegister : () => {
      this.onViewChange(SDP_CONSTANTS.MFAV2_USECASES.ONBOARDING)
    },
     authOTPSuccess:() => {
      this.authTypeChange.emit(AuthenticatorTypes.AT_OOBEML);
      this.onViewChange(SDP_CONSTANTS.MFAV2_USECASES.CREATE_PASSWORD);    
    },  
     authOTPError : (error: HttpErrorResponse) => {
      this.loaderService.hide()
      if (error.error['hid_failure'].reason === 44) {
        this.error$$.next(
          this.translateService.instant('validations.loginFailed'),
        );
      }
    },
    _validateTemporaryPassword: ()=> {      
      this.hidAuthService.authenticateOTP(
        this.form.get(this.FORM_FIELDS_LIB.LOGIN.USER_NAME)?.value,
        this.form.get(this.FORM_FIELDS_LIB.LOGIN.PASSWORD)?.value,
        this.Factor1.authOTPSuccess.bind(this),
        this.Factor1.authOTPError.bind(this),
      );
    },
    _authUserSuccess :() => {

      //RMS Factor 1 -  Password Success
      if (environment.module_rms) {     
        this._rmsProcessor.LoginStep(true,1,this.user.userInternalId,this.RMSfirstFactor, ()=>{})             
      }
     
      this.hidtargetview.emit(SDP_CONSTANTS.VIEWS.BANKING);
      this.onViewChange(SDP_CONSTANTS.MFAV2_USECASES.FACTOR2)
    },  
     _authUserError : (error: HttpErrorResponse) => {
      if (error.error.error === 'invalid_grant') {
           //RMS Factor 1 -  Password Fail
        if (environment.module_rms) {       
          this._rmsProcessor.LoginStep(false,1,this.user.userInternalId,'password',()=>{})         
        }
        this.error$$.next(
          this.translateService.instant('validations.loginFailed'),
        );
      }
    },
    _validatePUSH : () => {
      this.RMSfirstFactor = 'none'
      this.Factor1._authUserSuccess()
    },  
    _validatePassword : () => {
      this.RMSfirstFactor = 'password'     
      this.hidAuthService.authenticateUser(
        this.userName,
        this.password,
        this.Factor1._authUserSuccess.bind(this),
        this.Factor1._authUserError.bind(this),
      );
    },
    onSubmit: () =>{      
      this.loaderService.show()
      this.submit();
      if (this.form.valid) {
        if (this.newUser) {
          this.Factor1._validateTemporaryPassword();
          
        } else {        
          this.Factor1._validatePassword();           
          
        }
      }
    },
    onForgotPassword: () => {
      if (this.user[CONSTS.FACTOR1.USER_AUTHENTICATOR].authenticators.length === 0) {
        this.error$$.next(
          this.translateService.instant('searchUser.noAuthenticator'),
        );
        return;
      }
  
      const hasStdPwdAuthenticator = this.hidAuthService.hasAuthenticator(
        this.user,
        AuthenticatorTypes.AT_STDPWD,
      );
  
      const hasOOBEmailAuthenticator = this.hidAuthService.hasAuthenticator(
        this.user,
        AuthenticatorTypes.AT_OOBEML,
      );
  
      if (hasStdPwdAuthenticator && hasOOBEmailAuthenticator) {
        this.onViewChange(SDP_CONSTANTS.MFAV2_USECASES.FORGOT_PASSWORD_OTP);
      } else if (!hasStdPwdAuthenticator && hasOOBEmailAuthenticator) {
        setTimeout(this.Factor1._raiseUserFoundAlert.bind(this), 100);
      } else if (hasStdPwdAuthenticator && !hasOOBEmailAuthenticator) {
        setTimeout(this.Factor1._raiseUserNotFoundAlert.bind(this), 100);
      }
    },
    sendOTP:()=>{
      this.hidAuthService.sendOTP(this.form.get(this.FORM_FIELDS_LIB.LOGIN.USER_NAME)?.value);      
    },
    init:()=>{

    }
  } // Login View - 1st Factor For MFAv2


  public Onboarding () {

    let  _startManualOnboarding = () => {
      this.onViewChange(SDP_CONSTANTS.MFAV2_USECASES.MANUAL_REGISTER);
    }
  
    let  _startDigitalOnboarding = () => {   
      this.onViewChange(SDP_CONSTANTS.MFAV2_USECASES.DIGITAL_ONBOARDING);
    }
  
  
    let _startNuDigitalOnboarding = () => {
      this.onViewChange(SDP_CONSTANTS.VIEWS.ONBOARDING_WEBVIEW);
    } 

    this.submit();

    switch (this.registration) {
      
      // case for WEBVIEW TYPE
      // same as Digital, But instead of sending link, it should just create UUID and redirect to modified onboarding-webview. 
      // will show loader and iframe ;)


      case (SDP_CONSTANTS.ONBOARDING_TYPES.DIGITAL):
        _startDigitalOnboarding();
        break;

      case (SDP_CONSTANTS.ONBOARDING_TYPES.NuDIGITAL):
          _startNuDigitalOnboarding();
        break;
        
      case SDP_CONSTANTS.ONBOARDING_TYPES.MANUAL:
        _startManualOnboarding();
        break;
    }


   
  } // Choose Onboarding
  public CreatePassword = {
    _setUserAttributes: () => {
      const userAttributes = this.user[CONSTS.CREATE_PASSWORD.USER_ATTRIBUTE].attributes.reduce(
        (acc: string[], curr: UserAttribute) => {
          if (curr.name !== CONSTS.CREATE_PASSWORD.TITLE) {
            acc.push(curr.value.toLowerCase());
          }
          return acc;
        },
        [],
      );
  
      const emails = this.user.emails.map((email: { value: string }) =>
        email.value.toLowerCase(),
      );
  
      this.userAttributes = [
        ...userAttributes,
        ...emails,
        this.user.externalId?.toLowerCase(),
        this.user.userName?.toLowerCase(),
      ];
    },
    _updatePasswordPolicy: (passwordPolicyResponse: any) => {
      this.passwordPolicy =
        passwordPolicyResponse[CONSTS.CREATE_PASSWORD.AUTHENTICATOR_PASSWORD].passwordpolicy;
  
      const passwordPolicy = this.onBoardingService.getPasswordPolicy(
        this.passwordPolicy,
      );
      const maxLengthError =
        passwordPolicyResponse[CONSTS.CREATE_PASSWORD.AUTHENTICATOR_PASSWORD].passwordpolicy
          .maxLength;
      const minLengthError =
        passwordPolicyResponse[CONSTS.CREATE_PASSWORD.AUTHENTICATOR_PASSWORD].passwordpolicy
          .minLength;
      const minDiffCharsError =
        passwordPolicyResponse[CONSTS.CREATE_PASSWORD.AUTHENTICATOR_PASSWORD].passwordpolicy
          .minDiffChars;
  
      passwordPolicy.passwordGuidelines.forEach(
        (guideline: string, index: number) => {
          this.translateService
            .get(guideline, {
              minLength: minLengthError,
              maxLength: maxLengthError,
              minDiffChars: minDiffCharsError,
            })
            .subscribe((text: string) => {
              let val = passwordPolicy.passwordGuidelines[index];
              passwordPolicy.policyMap[val.substr(val.indexOf('.') + 1)].msg =
                text;
              passwordPolicy.passwordGuidelines[index] = text;
            });
        },
      );
  
      this.policy = passwordPolicy;
    },
    isPasswordValid: ():boolean => {
      const policyMap = this.policy?.policyMap;

      let valid = true;
  
      for (const constraint in policyMap) {
        if (
          constraint !== CONSTS.CREATE_PASSWORD.NOT_OLD_PASSWORD &&
          policyMap.hasOwnProperty(constraint) &&
          !policyMap[constraint].valid
        ) {
          valid = false;
          break;
        }
      }
  
      return valid;
    },   
    onSubmit:()=>{
      this.submit();
 
      if (this.form.invalid || !this.CreatePassword.isPasswordValid()) {
        return;
      }
  
      if (this.authenticatorType === AuthenticatorTypes.AT_STDPWD) {
       
        this.hidAuthService.checkAndResetPassword(
          this.passwordCreate,
          this.CreatePassword._postSubmit.bind(this),
          this.CreatePassword._errorCb.bind(this),
        );
      } else {
        this.onBoardingService.password = this.passwordCreate;
           
        this.CreatePassword._postSubmit();
      }
    },
    _postSubmit: () => {
      if (this.authenticatorType === AuthenticatorTypes.AT_STDPWD) {
        this.flowtype.emit(SDP_CONSTANTS.FLOW_TYPES.PASSWORD);
        this.onViewChange(SDP_CONSTANTS.MFAV2_USECASES.FLOW_END);
      } else {
        this.flowtype.emit(SDP_CONSTANTS.FLOW_TYPES.TEMPORARY_PASSWORD);
        this.onViewChange(SDP_CONSTANTS.MFAV2_USECASES.ADD_DEVICE);
      }
    },
    _errorCb: (error: HttpErrorResponse) => {
      if (error.error.scimType === 'invalidValue') {
        this.policy.policyMap.notOldPassword.valid = false;
    }
  },
  validate: (password: string): void => {
    this.policy.policyMap.notOldPassword.valid = undefined;

    this.policy.policyMap.minMaxLength.valid =
      password.length >= this.passwordPolicy.minLength &&
      password.length <= this.passwordPolicy.maxLength;

    if (this.passwordPolicy.notSequence) {
      let valid = true;

      if (Utils.containsConsecutiveCharSequence(password)) {
        valid = false;
      }

      if (valid && Utils.containsConsecutiveNumberSequence(password)) {
        valid = false;
      }

      this.policy.policyMap.notSequence.valid = valid;
    }

    if (this.passwordPolicy.minDiffChars) {
      this.policy.policyMap.minDiffChars.valid = Utils.containsDiffChars(
        password,
        this.passwordPolicy.minDiffChars,
      );
    }

    if (this.passwordPolicy.notUserAttribute) {
      const passwordLowerCase = password.toLowerCase();

      this.policy.policyMap.notUserAttribute.valid =
        !this.userAttributes.includes(passwordLowerCase);
    }

    if (!this.passwordPolicy.notEnglish) {
      this.policy.policyMap.notEnglish.valid = /^[A-Za-z]+$/.test(password);
    }
  }
  
  }  // Create Password  
  public Factor2 = {
     _fetchUserDevicesSuccess : (response: any) => {
      if (response.totalResults === 0) {
        this.noDeviceFound = true;
      } else {
        this.Factor2._checkDevices(response.resources);
      }
  
      this.cd.detectChanges();
    },   // HID Auth Service Device Check
    _checkDevices:(devices: any[]) => {
      const deviceTypes = Object.values(SDP_CONSTANTS.PUSH_DEVICE_TYPES);
  
      const filteredDevices = devices.filter(
        (device: any) =>
          device.status.active && deviceTypes.includes(device.type),
      );
  
      this.devices = filteredDevices;
  
      const numberOfDevices = filteredDevices.length;
  


           


        /// rewrite next thing, oh my god.
        // rewritten past thing, thank me
        window.console.log(this.hidDeviceDataService.selectedDevice)

        if( numberOfDevices === 1){ this.hidDeviceDataService.selectedDevice = filteredDevices[0];       } // autoselects device if only 1

        if (this.hidDeviceDataService.selectedDevice) { // selected device - executing approval
         
          if (this.UseCase == SDP_CONSTANTS.MFAV2_USECASES.HID_APPROVE_PUSH) {this.activeView = SDP_CONSTANTS.VIEWS.HID_APPROVE_PUSH;}
          if (this.UseCase == SDP_CONSTANTS.MFAV2_USECASES.HID_APPROVE_CODE) {this.activeView = SDP_CONSTANTS.VIEWS.HID_APPROVE_OTP;}
        } else { // device not selected
        
          if (numberOfDevices === 0) { // no device
            
            this.noDeviceFound = true;
          } else if (numberOfDevices > 1)  { // executing device selection process
           

            this.hidDeviceDataService.devices = filteredDevices;
            this.activeView = SDP_CONSTANTS.VIEWS.SELECT_PRIMARY_DEVICE;
          }
        }





      
    }    // HID Auth Service Device Check
  } // Factor 2   
  public ForgotPassword = {
    sendOTP: () => { this.hidAuthService.sendOTP(this.useremail);  },
    onOtpChange: (otp: string) => { this.otp = otp; },
    onSubmit: () => {
      this.incorrectOtp = false;
  
      if (this.ngOtpInput?.otpForm?.invalid) {
        return;
      }
  
      this.hidAuthService.authenticateOTP(
        this.useremail,
        this.otp,
        this.ForgotPassword.authOTPSuccess.bind(this),
        this.ForgotPassword.authOTPError.bind(this),
      );
    },
    authOTPSuccess: () => {
      this.authTypeChange.emit(AuthenticatorTypes.AT_STDPWD);
      this.onViewChange(SDP_CONSTANTS.MFAV2_USECASES.CREATE_PASSWORD)     
    },
    authOTPError: (error: HttpErrorResponse) => {
      // Reason code 44 comes from hid auth service
      if (error.error['hid_failure'].reason === 44) {
        this.incorrectOtp = true;
      }
    }

  }   // Forgot Password 


/// FIDO
/// We preload challenge

  public Fido = {
    clean: () =>{
        this.fidoChallenge = ''
        this.fidoCredOptions = ''
        this.fidoCredentialResponse = ''
        this.hidAuthService.fidoClean()        
    },
    _getChallenge: () => {
      let setChallenge = (response:any) =>{
        this.fidoChallenge = response.
        window.console.log(response)
      }
      let fail = () => {
        window.console.log('fido-getChallenge-fail')
      }
      this.hidAuthService.fidoPar( () => {
      this.hidAuthService.fidoGetLoginChallenge(
        (res)=> setChallenge(res),
        ()=> fail()
      )
      },
      ()=>{}
      )
      
    },
    _registerVerify: (      
    ) => {

      let worked = () =>{
        
        window.console.log('fido-register-yes')
      }
      let fail = () => {
        window.console.log('fido-register-no')
      }
      this.hidAuthService.fidoRegisterCredential(
        this.fidoCredentialResponse,
        ()=> worked(),
        ()=> fail()
      )
    },
    _loginVerify: ()  => {
      let worked = () =>{
        
        window.console.log('fido-loginVerify-yes')
      }
      let fail = () => {
        window.console.log('fido-loginVerify-no')
      }
      this.hidAuthService.fidoLogin(
        this.fidoCredentialResponse,
        ()=> worked(),
        ()=> fail()
      )
    },
    _getCredOptions: ()=>{
      let setCredOptions = (options:any) =>{
        window.console.log(options)
        this.fidoCredOptions = options
        window.console.log('fido-getCredOptions-yes')
      }
      let fail = () => {
        window.console.log('fido-getCredOptions-no')
      }
      this.hidAuthService.fidoGetCredentialOptions(        
        (options)=> setCredOptions(options),
        ()=> fail()
      )
    },
    // Login: () => {
    //   if (this.fidoChallenge) {
    //   navigator.credentials.create(this.fidoChallenge)
    //   .subscribe(
    //     (res:any)=> {  }
    //   )
    // }



    // }, //1. Spawn User Facing Registration
    // Register: () => {
    //   if (this.fidoCredentialResponse) {
    //   navigator.credentials.create(this.fidoChallenge)
    //   .subscribe(
    //     (res:any) => {}
    //   )
    // }
    // }
  
  }
        
  




  private _loginToBank(){    
    if (environment.module_rms) { this._rmsProcessor.LoginFinished()}
    const sessionUser = {
      userExternalId: this.hidAuthService.userExternalId,
      userInternalId: this.hidAuthService.userInternalId,
    } as SessionUser;
    
    this.authService.login(sessionUser);
    this.router.navigateByUrl(SDP_CONSTANTS.ROUTES.BANKING)
  } // Creates Session

  //Logic Flat Supporting Methods.    
  public goToHome() { this.router.navigateByUrl(SDP_CONSTANTS.ROUTES.HOME); } // Simple Navigate Home
  public goToBank(){  this.router.navigateByUrl(this.fallbackButton) } // Simple Navigate To Bank (After S2A - if valid cookie
  
  // Visuals and logicSupporting Methods
  deleteUser() {
    this.hidAuthService.deleteCurrentUser(this._deleteUserSuccess.bind(this));
  }
  private _deleteUserSuccess() {
    this.onBoardingService.email = '';
    this.onViewChange(SDP_CONSTANTS.MFAV2_USECASES.SEARCH_USER);
  }
  onAuthenticatorTypeChange(authType: any) {
    this.authenticatorType = authType;
  } 

  onShowHIDApproveTargetView() {
    this.hidDeviceDataService.selectedDevice = ''

    if (this.showProcessing) {  this.loaderService.show }
    if (this.successScreen) {      

        
         if(this.transactionSigning){ this._rmsProcessor.TransactionComplete(this.appPaymentId)}    

        this.activeView = SDP_CONSTANTS.MFAV2_USECASES.FLOW_END;

      } else {
  
        this.showsecuritytargetview.emit();
        this._loginToBank()
      }
  }  
  onFlowType(flowTypeMessage: string) {
    this.flowEndMessage = flowTypeMessage;
  }
  onViewChange(view: any) {
    this._clean()
    if (
      Object.values(SDP_CONSTANTS.MFAV2_USECASES).includes(view) 
       
    ) {           
      
      this.UseCase  = view as string;      
      this.useremail = this.onBoardingService.email
      this.user = this.hidAuthService.getUser();      
      this.initMFAv2() 

    } else if (
      [
        SDP_CONSTANTS.VIEWS.HID_APPROVE_PUSH,
        SDP_CONSTANTS.VIEWS.HID_APPROVE_OTP,
        SDP_CONSTANTS.VIEWS.HID_APPROVE_TRANS_OTP,
        SDP_CONSTANTS.VIEWS.HID_APPROVE_FLOW,
      ].includes(view)
    ) {
      this.activeView = view as string;
    }
     else {
      window.console.log(view + ' not included')
      this.useremail = this.onBoardingService.email
      this.user = this.hidAuthService.getUser();
      this.UseCase  = view as string;
      this.viewchange.emit(view);
    }
  }
  onFlowEnd() {
    this._clean()
    this.successScreen = false;
    this.onViewChange(SDP_CONSTANTS.MFAV2_USECASES.FACTOR1);
  }
  onRejected() {
    this._clean()
    this.successScreen = false;
    this.rejected.emit();
  }
  onCancel() {
    this._clean()    
    this.onViewChange(SDP_CONSTANTS.MFAV2_USECASES.SEARCH_USER);
    this.cancelled.emit();
    
  }

  onAddDeviceViewChange(view: string) {
    this.addDeviceFlowView = view;
  }

  ngOnDestroy(){
    this._clean()   
  }

  private _clean(){   
    if(this.hidApproveAuthRequestId) {this.hidAuthService.cancelPushNotification(this.hidApproveAuthRequestId,
      ()=>{  this.hidApproveAuthRequestId = ''},
      ()=>{ }
    );

    
   }
   this.Fido.clean()
    clearInterval(this.timerCheck)
    clearInterval(this.timer);
    this.timerCheck, this.timer = null
    this.devices,
    this.noDeviceFound, 
    this.hidDeviceDataService.selectedDevice,
    this.hidDeviceDataService.devices,
    this.hidDeviceDataService.deviceExternalId,
    this.hidDeviceDataService.devicePushId,
    this.hidDeviceDataService.owner,
    this.onBoardingService.userName,
    this.user,

   
    this.useremail = ''
  }

  
}
