import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards';
import { SDP_CONSTANTS } from './core/helpers';
import { ProductsComponent } from './views/products/products.component';

const routes: Routes = [
  {
    path: '',
    component: ProductsComponent,
  },
  {
    path: SDP_CONSTANTS.ROUTES.BANKING,
    loadChildren: () =>
      import('./views/banking/banking.module').then(m => m.BankingModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },  
  {
     path: SDP_CONSTANTS.ROUTES.TESTPAGE,
     loadChildren: () =>
       import('./views/testpage/testpage.module').then(
       m => m.testpageModule,
       ),
     },
  {
    path: SDP_CONSTANTS.ROUTES.SECURITY,
  loadChildren: () =>
    import('./views/security/security.module').then(
      m => m.SecurityModule,
    ),
  },
  {
    path: SDP_CONSTANTS.ROUTES.IDPCALLBACK,
    loadChildren: () =>
    import('./views/callback/callback.module').then(m => m.CallbackModule),
    canLoad: [],
    canActivate: [],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'top',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
