<div class="onboarding-status-tracker"> </div>


<div class="text-center mb-5">
  <div class="sdp-ob-form-header">
    <h3 class="sdp-ob-title">{{ 'onbordingStatus.title' | translate }}</h3>
    <h3 class="sdp-ob-title">{{ 'onbordingStatus.product' | translate }}</h3>
  </div>
  <span class="sr-only"> {{ progresstext }} </span>
  <div class="progress">   
    <div class="progress-bar" role="progressbar" aria-valuenow=progressvaluenow aria-valuemin="0" aria-valuemax="100" [style.width.%]=progressvaluenow>
     
    </div>
  </div>
</div>

<ng-container *ngIf="IDVStat===99">
  Your request failed. Please check your documents or add more light.

<button (click)="_generateIDVLink()">Generate Validation Request</button>
</ng-container>


<ng-container *ngIf="IDVStat===0">
Loading.
</ng-container>

<ng-container *ngIf="IDVStat===1">
  <ng-container *ngIf="this.idvonly===true">
Would you like to recieve receipt via email?
<br />
<button
          class="btn btn-primary-mb submit-btn"
          type="button"
          (click)="onEmailCheck()"
        >
          Skip
    </button>
  </ng-container>
</ng-container>

<ng-container *ngIf="IDVStat===2">
      <ng-container *ngIf="this.idvonly===true">
      


    

      <div class="w-100 mt-4 mb-5">
        <span *ngIf="this.qrError" style="color:red;"> Wrong Account Code </span>
        <div class="form-group text-left">
          Please input account code if you have one:
          <br />
          <input
           [(ngModel)]="inputAccountCode"   
          maxlength="6"          
          class="form-control"
          
          placeholder="{{defaultaccountcode}}"
        />     
        </div>
      </div>
  
      
    <div *ngIf="!buttonhide" vclass="text-right">
      <button
        type="submit"
        class="btn btn-primary-mb"
        (click)="_IDVFlowStep2Confirm()"
       
        #submitBtn
      >
        {{ 'confirm' | translate }}
      </button>
    </div>
    </ng-container>
</ng-container>

<ng-container *ngIf="IDVStat===3">

  <ng-container *ngIf="(!deviceDetectorService.isMobile()) && (!deviceOverride)">
    <p class="">{{ 'IDV2.QRCodeDesktop' | translate }}</p>

    <div class="d-flex justify-content-center">
      <ng-container *ngIf="qrCodeData">
        <div class="qrcode-holder">
         
              <qrcode 
              [qrdata]="qrCodeData"    
              [colorDark]="'#3f51b5'"
              [colorLight]="'#ffffff'" 
              [elementType]="'img'"
              [errorCorrectionLevel]="'M'"              
              [cssClass]="'qrimg'"

              ></qrcode>
              <br />
         
                <a *ngIf="(!deviceOverride)"
                class="                
                
                btn-primary 

                "
                [href]="" 
                         
                style="height: 100%; padding:5px;"
                (click)="
                  setDeviceOverride()
                "
                >
                 
                <span>Do you want to continue on desktop?</span>
              </a>
        </div>
      </ng-container>
      <ng-container *ngIf="!qrCodeData">
      <img
          height="80%"
          width="80%"
          src="./../../../../assets/images/hid-approve/qr-placeholder.jpg"
          alt="QR Code Placeholder"
        /> 
      </ng-container>
   
    </div>



  </ng-container>


  <ng-container *ngIf="deviceDetectorService.isMobile() || deviceOverride">
     
     <p class="">{{ 'IDV2.QRCodeMobile'| translate }}</p>
     <div class="d-flex justify-content-center">

      <ng-container *ngIf="qrCodeData">


      <ng-container *ngIf="!enableIframe">
        <div>
        <a  class="                
        w-100 
        btn btn-secondary btn-lg 
        d-flex align-items-center justify-content-center
        "
        (click)="openNewTab()"
        target="_blank">Continue Here</a>     
        </div>
      </ng-container>


      <ng-container *ngIf="enableIframe">
        <div>
       <iframe [src]="urlSafe" class="iframeClass" frameborder="0" border="0" cellspacing="0"
       allow="camera location https://cfwebt.amalgam-east.ascendant.cloud"       
       
       ></iframe> 
        </div>
      </ng-container>

        
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="IDVResponse">
    <br >
    <p style="text-align: center;">Keep this window open.</p>
    <ng-container *ngIf="IDVPII.DQL_Final_Surname_Result"> <p style="text-align: center;"> We are now validating your data.</p> </ng-container>


   
  </ng-container>
  <div class="text-right mb-0">
    <button class="btn btn-outline-primary" (click)="onCancel()">
      {{ 'cancel' | translate }}
    </button>
  </div>

</ng-container>

 <ng-container *ngIf="IDVStat===4">  
  
  <p> Thank you {{ IDVPII.FullName }} for successfuly going though our Identity Validation</p>
  <div class="image-selfie-div">
    <p> Recorded Selfie Picture:</p>
     
    <ng-container *ngIf="IDVPII.imageSelfie">  
      <img [src]=IDVPII.imageSelfie  class="image-selfie"> 
      </ng-container>

  </div>
  <div class="image-selfie-div">
    <p> We have recorded your submission from <a [href]=gmappslink target="_blank"> here. (Continue to Google Maps)</a>
      
    </div>
      <button class="btn btn-info btn-sm"(click)="onToggleSettings()" id="bt">
        {{buttonName}}
     </button>
  
      <ng-container *ngIf="optionsShow">
        <pre> 
          {{ IDVPII | json }}
        </pre>
      </ng-container>

  <!-- Here comes manual data thing -->
  <!-- It needs to have prepared data from idv prefilled. -->
  <div class="text-right mb-0">
    <button class="btn btn-outline-primary" (click)="onCancel()">
      {{ 'cancel' | translate }}
    </button>
  </div>  
  <button
          class="btn btn-primary-mb submit-btn"
          type="button"
          (click)="onSubmitClick()"
        >
          {{ 'manualRegistration.confirmAndSubmit' | translate }}
    </button>
    

  
  </ng-container>

<ng-container *ngIf="IDVStat===5">
  Thank you!
  
</ng-container>