import { Component, EventEmitter, Output } from '@angular/core';
import { BaseForm } from '@app/core/base/base-form';
import { SDP_CONSTANTS } from './../../../core/helpers';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'mfav2-base',
  template: '',
})

export class MFAv2Base extends BaseForm {
  declare form: FormGroup;
  error$$ = new Subject<string>();
  @Output() viewchange = new EventEmitter<string>();
  submitted = false;
  SDP_CONSTANTS = SDP_CONSTANTS;

  constructor() {
    super();
  }


 // To make SDP_CONSTANTS available for use in template files. 


  // For holding client side validation errors for display purpose.

}
