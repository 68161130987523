import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticatorTypes } from '@app/core/helpers/enums';
import { AuthenticatorType } from '@app/core/helpers/types';
import { environment } from '@environments/environment';
import {
  AuthService,
  LoaderService,
  BankingDataService,
  HidAuthDataService,
} from '@app/core/services';
import { SDP_CONSTANTS } from '@app/core/helpers';
import { OnboardingService } from '../onboarding/onboarding.service';
import { HidDeviceDataService } from '../hid-device-data/hid-device-data.service';

@Injectable({
  providedIn: 'root',
})
export class HidAuthenticationService {

  // Id Token used by the API interceptor
  idToken!: string;

  // Access Token used by the API interceptor
  accessToken!: string;

  // Logged-in user
  user!: any;

  // Logged-in user's external id
  userExternalId!: string;

  // Logged-in user's internal id
  userInternalId!: number;

  AttributeIDs: Array<string> = ['IDVSTAT', 'IDVREF', 'serialnumber'];
  setup: number = 0;

  // fido
  request_uri: string = '';
  csrf: string = '';




  constructor(
    private readonly authService: AuthService,
    private readonly loaderService: LoaderService,
    private readonly onBoardingService: OnboardingService,
    private readonly bankingDataService: BankingDataService,
    private readonly hidAuthDataService: HidAuthDataService,
    private readonly hidDeviceDataService: HidDeviceDataService,
  ) {

  }


  private cleanInputForAS(input: string) {
    // cleans input from fields, to avoid special character issues
    // allowing only basic alphanumeric
    // will probably break some user stuff later
    if (!input) { input = ' ' }
    let clean = input.replace(/[^a-zA-Z0-9]/gi, '')
    return clean
  }

  clearUser() {
    this.user = undefined;
    this.userExternalId = '';
    this.userInternalId = -1;
  }

  setUser(userRawResponse: any) {
    const user = userRawResponse.resources[0];
    this.user = user;
    this.userExternalId = user.externalId;
    this.userInternalId = +Number(user.id);
    //process devices
    //establish avaliable factors
    

  }

  getUser() {
    return this.user;
  }

  getSearchUserPayload(externalId: string) {
    return {
      schemas: ['urn:ietf:params:scim:api:messages:2.0:SearchRequest'],
      filter: `username eq "${externalId}"`,
      sortBy: 'id',
      sortOrder: 'descending',
      startIndex: 0,
      count: 100,
    };
  }

  getCreateUserPayload(email: string) {
    return {
      schemas: ['urn:ietf:params:scim:schemas:core:2.0:User'],
      externalId: email,
      emails: [
        {
          value: email,
          type: 'work',
        },
      ],
      groups: [
        {
          value: 'UG_ROOT',
        },
      ],
      meta: {
        resourceType: 'User',
      },
    };
  }

  getRegisterOOBEMLAuthenticatorPayload(
    temporaryPassword: string | undefined = undefined,
    userInternalId = this.userInternalId,
    authenticatorType = AuthenticatorTypes.AT_OOBEML,
  ) {
    const payload = {
      activationCode: temporaryPassword,
      schemas: ['urn:hid:scim:api:idp:2.0:Authenticator'],
      'urn:hid:scim:api:idp:2.0:Action': {
        action: 'REGISTER-OOB',
        attributes: [
          {
            name: 'OOB_DEVICETYPE_CODE',
            value: 'DT_OOBEML',
          },
        ],
      },
      owner: {
        value:
          userInternalId || this.authService.getCurrentUser()?.userInternalId,
      },
      policy: {
        value: authenticatorType,
      },
    };

    if (!temporaryPassword) {
      delete payload.activationCode;
    }

    return payload;
  }

  getUnRegisterAuthenticatorPayload(userExternalId: string) {
    return {
      schemas: ['urn:hid:scim:api:idp:2.0:Action'],
      'urn:hid:scim:api:idp:2.0:Action': {
        action: 'UNREGISTER-OOB',
        attributes: [
          {
            name: 'USER.EXTERNALID',
            value:
              userExternalId ||
              this.userExternalId ||
              this.authService.getCurrentUser()?.userExternalId,
          },
        ],
      },
    };
  }

  getCreatePasswordAuthenticatorPayload(
    username: string,
    password: string,
    userInternalId = this.userInternalId,
  ) {
    return {
      schemas: ['urn:hid:scim:api:idp:2.0:Authenticator'],
      policy: {
        value: 'AT_STDPWD',
      },
      status: {
        status: 'ENABLED',
        expiryDate: '2040-05-15T18:15:21+00:00',
        startDate: '2015-05-15T18:15:21+00:00',
      },
      owner: {
        value:
          (userInternalId ||
            this.authService.getCurrentUser()?.userInternalId) + '',
      },
      'urn:hid:scim:api:idp:2.0:Password': {
        username,
        password,
      },
    };
  }

  createUserManualRegistrationPayload(manualUserDetails: any) {
    let cleandata = {
      addressLine1: this.cleanInputForAS(manualUserDetails.addressLine1),
      addressLine2: this.cleanInputForAS(manualUserDetails.addressLine2),
      country: this.cleanInputForAS(manualUserDetails.country),
      townOrCity: this.cleanInputForAS(manualUserDetails.townOrCity),
      lastName: this.cleanInputForAS(manualUserDetails.lastName),
      firstName: this.cleanInputForAS(manualUserDetails.firstName),
      dateOfBirth: this.cleanInputForAS(manualUserDetails.dateOfBirth),
      zipOrPostalCode: this.cleanInputForAS(manualUserDetails.zipOrPostalCode)
    }


    return {
      schemas: [
        'urn:ietf:params:scim:schemas:core:2.0:User',
        'urn:hid:scim:api:idp:2.0:Attribute',
        'urn:hid:scim:api:idp:2.0:UserDevice',
      ],
      externalId: manualUserDetails.emailAddress,
      emails: [
        {
          value: manualUserDetails.emailAddress,
          type: 'work',
        },
      ],
      name: {
        familyName: cleandata.lastName,
        givenName: cleandata.firstName,
      },
      groups: [
        {
          value: 'UG_ROOT',
        },
      ],
      meta: {
        resourceType: 'User',
      },
      'urn:hid:scim:api:idp:2.0:UserAttribute': {
        attributes: [
          {
            name: 'TITLE',
            type: 'string',
            value: '',
          },
          {
            name: 'ADDRESS1',
            type: 'string',
            value: cleandata.addressLine1.replace('/', ''),
          },
          {
            name: 'ADDRESS2',
            type: 'string',
            value: cleandata.addressLine2.replace('/', ''),
          },
          {
            name: 'ATT_CNY_ID',
            type: 'string',
            value: cleandata.country,
          },
          {
            name: 'CITY',
            type: 'string',
            value: cleandata.townOrCity,
          },
          {
            name: 'POSTCODE',
            type: 'string',
            value: cleandata.zipOrPostalCode,
          },
          {
            name: 'ATR_MOBILE',
            type: 'string',
            value: manualUserDetails.telephoneNumber.e164Number,
          },
        ],
      },
    };
  }

  getSendOOBEmailPayload(email: string, activationCode: string) {
    return {
      grant_type: 'password',
      username: email,
      password: activationCode,
      channel: 'CH_EXTRAPP',
      authType: 'AT_OOBEML',
      noToken: 1,
    };
  }

  getAuthenticateOOBEmailOTPPayload(userName: string, otp: number) {
    return {
      grant_type: 'password',
      username: userName,
      password: otp,
      channel: 'CH_EXTRAPP',
      authType: 'AT_OOBEML',
    };
  }

  getResetPasswordPayload(
    authenticatorType: AuthenticatorType,
    password: string,
  ) {
    return {
      schemas: [
        'urn:hid:scim:api:idp:2.0:Authenticator',
        'urn:hid:scim:api:idp:2.0:Password',
      ],
      id: `${this.userInternalId || this.authService.getCurrentUser()?.userInternalId
        }.${authenticatorType}`,
      status: { status: 'ENABLED', active: this.user.active },
      'urn:hid:scim:api:idp:2.0:Password': {
        username:
          this.userExternalId ||
          this.authService.getCurrentUser()?.userExternalId,
        password,
      },
    };
  }

  createGetUserDevicesPayload(userInternalId = this.userInternalId) {
    return {
      schemas: ['urn:ietf:params:scim:api:messages:2.0:SearchRequest'],
      filter: `owner.value eq ${userInternalId || this.authService.getCurrentUser()?.userInternalId
        }`,
      sortBy: 'id',
      sortOrder: 'descending',
      startIndex: 0,
      count: 100,
    };
  }

  createUpdateUserPayload(userDetails: any) {    //Extra Attributes to be checked and created on Auth Service if missing.
    return {
      schemas: [
        'urn:ietf:params:scim:schemas:core:2.0:User',
        'urn:hid:scim:api:idp:2.0:Attribute',
        'urn:hid:scim:api:idp:2.0:UserDevice',
      ],
      externalId: userDetails.userId,
      emails: [
        {
          value: userDetails.emailAddress,
          type: 'work',
        },
      ],
      name: {
        familyName: userDetails.lastName,
        givenName: userDetails.firstName,
      },
      groups: [
        {
          value: 'UG_ROOT',
        },
      ],
      meta: {
        resourceType: 'User',
      },
      'urn:hid:scim:api:idp:2.0:UserAttribute': {
        attributes: [
          {
            name: 'TITLE',
            type: 'string',
            value: '',
          },
          {
            name: 'ADDRESS1',
            type: 'string',
            value: userDetails.address.addressLine1,
          },
          {
            name: 'ADDRESS2',
            type: 'string',
            value: userDetails.address.addressLine2,
          },
          {
            name: 'ATT_CNY_ID',
            type: 'string',
            value: userDetails.address.country,
          },
          {
            name: 'CITY',
            type: 'string',
            value: userDetails.address.town,
          },
          {
            name: 'POSTCODE',
            type: 'string',
            value: userDetails.address.postalCode,
          },
          {
            name: 'ATR_MOBILE',
            type: 'string',
            value: userDetails.phoneno.e164Number,
          },
        ],
      },
    };
  }

  createUpdateUserAttributesForCallback() {

    return {
      schemas: [
        'urn:ietf:params:scim:schemas:core:2.0:User',
        'urn:hid:scim:api:idp:2.0:Attribute',
        'urn:hid:scim:api:idp:2.0:UserDevice',
      ],
      externalId: environment.hidAuth.clientId,
      'urn:hid:scim:api:idp:2.0:UserAttribute': {
        attributes: [
          {
            name: 'ATR_TOPIC',
            type: 'string',
            value: 'ActivIDAuthPortal',
          },
          {
            name: 'ATR_CIBACB',
            type: 'string',
            value: environment.hidAuth.callbackUrl,
          },
        ],
      },
      groups: [
        {
          value: 'UG_CLIENTID',
        },
      ],
    };
  }

  setIDVAttibute(IDVREF: string, IDVSTAT: string) {

    let atttributearr = []
    if (IDVREF == '') { } else {
      let a = {
        name: 'IDVREF',
        type: 'string',
        value: IDVREF,
      }
      atttributearr.push(a)
    }
    if (IDVSTAT == '') { } else {
      let a = {
        name: 'IDVSTAT',
        type: 'string',
        value: IDVSTAT,
      }
      atttributearr.push(a)
    }
    return {
      schemas: [
        'urn:ietf:params:scim:schemas:core:2.0:User',
        'urn:hid:scim:api:idp:2.0:Attribute',
        'urn:hid:scim:api:idp:2.0:UserDevice',
      ],
      externalId: environment.hidAuth.clientId,
      'urn:hid:scim:api:idp:2.0:UserAttribute': {
        attributes: atttributearr,
      },

    };
  }

  AddTSAuthenticatorSmsPayload() {
    return {
      schemas: [
        "urn:hid:scim:api:idp:2.0:Authenticator"
      ],
      "urn:hid:scim:api:idp:2.0:Action": {
        action: "REGISTER-OOB",
        attributes: [
          {
            name: "OOB_CREDENTIALTYPE_CODE",
            value: "CT_TXOOB"
          },
          {
            name: "OOB_DEVICETYPE_CODE",
            value: "DT_TXOOB"
          }
        ]
      },
      owner: {
        "display": this.userExternalId ||
          this.authService.getCurrentUser()?.userExternalId
      },
      policy: {
        value: "AT_TXOOB"
      },
      useActivationCode: false
    }
  }

  AddOobSmsAuthenticatorPayload() {
    return {
      schemas: [
        "urn:hid:scim:api:idp:2.0:Authenticator"
      ],
      policy: {
        value: "AT_OOBSMS"
      },
      owner: {
        value: this.userInternalId ||
          this.authService.getCurrentUser()?.userInternalId
      },
      activationCode: "Password01",
      "urn:hid:scim:api:idp:2.0:Action": {
        action: "REGISTER-OOB",
        attributes: [
          {
            name: "OOB_DEVICETYPE_CODE",
            value: "DT_OOBSMS"
          }
        ]
      }
    }
  }
  hasAuthenticator(user: any, authenticatorType: AuthenticatorTypes | string) {
    return (user || this.user)[
      'urn:hid:scim:api:idp:2.0:UserAuthenticator'
    ].authenticators.some(
      (authenticator: any) => authenticator.display === authenticatorType,
    );
  }

  isRegisteredUser(user = this.user) {
    return (
      this.hasAuthenticator(user, AuthenticatorTypes.AT_OOBEML) &&
      this.hasAuthenticator(user, AuthenticatorTypes.AT_STDPWD)
    );
  }

  isRegisteredUserPASA(user = this.user) {
    return (
      this.hasAuthenticator(user, AuthenticatorTypes.AT_OOBEML) &&
      this.hasAuthenticator(user, AuthenticatorTypes.AT_PASA)
    );
  }

  hasOOBEML(user = this.user) {
    return (
      this.hasAuthenticator(user, AuthenticatorTypes.AT_OOBEML)
    );
  }


  authenticateUser(
    userName: string,
    password: string,
    successCb: () => void,
    errorCb: (error: HttpErrorResponse) => void,
  ) {
    this.loaderService.show();
    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.idToken = authClientResponse['access_token'];
        this.hidAuthDataService
          .authenticateUser()
          .subscribe((authUserResponse: any) => {
            this.accessToken = authUserResponse['access_token'];
            this.hidAuthDataService
              .authenticateUser(userName, password)
              .subscribe(
                (response: any) => this._success(response, successCb),
                (error: HttpErrorResponse) => errorCb?.(error),
              );
          });
      });
  }

/// Add new parameters? Audit/log search?
  searchUser(
    externalId: string,
    successCb: (response: any) => void,
    errorCb?: (error: HttpErrorResponse) => void,
  ) {
    this.loaderService.show();
    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.idToken = authClientResponse['access_token'];
        this.hidAuthDataService
          .authenticateUser()
          .subscribe((authUserResponse: any) => {
            this.accessToken = authUserResponse['access_token'];
            this.hidAuthDataService
              .searchUser(this.getSearchUserPayload(externalId))
              .subscribe(
                (response: any) => {
                  this.loaderService.hide();
                  successCb(response);
                },
                (error: HttpErrorResponse) => errorCb?.(error),
              );
          });
      });
  }

  sendOTP(email: string) {
    this.loaderService.show();
    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.idToken = authClientResponse['access_token'];
        this.hidAuthDataService
          .authenticateUser()
          .subscribe((authUserResponse: any) => {
            this.accessToken = authUserResponse['access_token'];
            this.hidAuthDataService
              .registerAuthenticator(
                this.getRegisterOOBEMLAuthenticatorPayload(),
              )
              .subscribe((response: any) =>
                this._sendOOBEmail(email, response.activationCode),
              );
          });
      });
  }

  private _sendOOBEmail(email: string, activationCode: string) {
    this.hidAuthDataService
      .sendOOBEmail(this.getSendOOBEmailPayload(email, activationCode))
      .subscribe(_ => this.loaderService.hide());
  }



  cancelPushNotification(
    auth_req_id: string,    
    successCb: (response: any) => void,
    errorCb: () => void,
  ) {
     this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.idToken = authClientResponse['access_token'];
        this.hidAuthDataService
          .authenticateUser()
          .subscribe((authUserResponse: any) => {
            this.accessToken = authUserResponse['access_token'];

            this.hidAuthDataService
              .cibaDelete(auth_req_id)
              .subscribe(
                (response: any) => successCb?.(response),
                _ => errorCb?.(),
              );
          });
      })
  }

  authenticateCode(
    code: string,
    redirect_uri: string,
    successCb: (response: any) => void,
    errorCb: () => void,
  ) {


    this.loaderService.show();
    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.idToken = authClientResponse['access_token'];
        this.hidAuthDataService
          .authenticateUser()
          .subscribe((authUserResponse: any) => {
            this.accessToken = authUserResponse['access_token'];

            this.hidAuthDataService
              .authenticateCode(code, redirect_uri)
              .subscribe(
                (response: any) => successCb?.(response),
                _ => errorCb?.(),
              );
          });
      })
  }


  authenticateOTP(
    email: string,
    otp: string,
    successCb: () => void,
    errorCb: (error: HttpErrorResponse) => void,
  ) {
    this.loaderService.show();
    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.idToken = authClientResponse['access_token'];
        this.hidAuthDataService
          .authenticateUser()
          .subscribe((authUserResponse: any) => {
            this.accessToken = authUserResponse['access_token'];
            this.hidAuthDataService
              .authenticateOOBEmailOTP(
                this.getAuthenticateOOBEmailOTPPayload(email, +otp),
              )
              .subscribe(
                (response: any) => this._success(response, successCb),
                (error: HttpErrorResponse) => errorCb?.(error),
              );
          });
      });
  }

  checkAndRegisterPASA(
    successCb: () => void,
    errorCb?: (error: HttpErrorResponse) => void,
  ) {
    this._registerPASA(successCb);


  }

  private _registerPASA(

    successCb: () => void,
  ) {
    this.loaderService.show();
    this.hidAuthDataService.registerAuthenticator(this.AddOobSmsAuthenticatorPayload())
      .subscribe((response: any) => {
        this.hidAuthDataService.registerAuthenticator(this.AddTSAuthenticatorSmsPayload())
          .subscribe(_ => {
            this.loaderService.hide();
            successCb?.();
          });
      });
  }



  checkAndResetPassword(
    password: string,
    successCb: () => void,
    errorCb?: (error: HttpErrorResponse) => void,
  ) {
    if (!this.hasAuthenticator(undefined, AuthenticatorTypes.AT_STDPWD)) {
      this._createPasswordAuthenticator(password, successCb);
    } else {
      this._resetPassword(password, successCb, errorCb);
    }
  }

  private _resetPassword(
    password: string,
    successCb: () => void,
    errorCb?: (error: HttpErrorResponse) => void,
  ) {
    const authenticatorId = `${this.userInternalId}.AT_STDPWD`;

    this.loaderService.show();
    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.idToken = authClientResponse['access_token'];
        this.hidAuthDataService
          .authenticateUser()
          .subscribe((authUserResponse: any) => {
            this.accessToken = authUserResponse['access_token'];
            this.hidAuthDataService
              .resetPassword(
                authenticatorId,
                this.getResetPasswordPayload(
                  AuthenticatorTypes.AT_STDPWD,
                  password,
                ),
              )
              .subscribe(
                (response: any) => this._success(response, successCb),
                (error: HttpErrorResponse) => errorCb?.(error),
              );
          });
      });
  }

  private _createPasswordAuthenticator(
    password: string,
    successCb: () => void,
  ) {
    this.loaderService.show();
    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.idToken = authClientResponse['access_token'];
        this.hidAuthDataService
          .authenticateUser()
          .subscribe((authUserResponse: any) => {
            this.accessToken = authUserResponse['access_token'];
            this.hidAuthDataService
              .registerAuthenticator(
                this.getCreatePasswordAuthenticatorPayload(
                  this.getUser().userName,
                  password,
                ),
              )
              .subscribe((response: any) => {
                this.accessToken = authUserResponse['access_token'];
                this.hidAuthDataService.registerAuthenticator(this.AddOobSmsAuthenticatorPayload())
                  .subscribe((response: any) => {
                    this.accessToken = authUserResponse['access_token'];
                    this.hidAuthDataService.registerAuthenticator(this.AddTSAuthenticatorSmsPayload())
                      .subscribe(_ => {
                        this.loaderService.hide();
                        successCb?.();
                      });
                  });
              });
          });
      });
  }

  /**
   * Deletes currently logged in user from HID auth service and major bank portal
   * @param successCb Success callback
   */
  deleteCurrentUser(successCb?: () => void) {
    this.loaderService.show();
    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.idToken = authClientResponse['access_token'];
        this.hidAuthDataService
          .authenticateUser()
          .subscribe((authUserResponse: any) => {
            this.accessToken = authUserResponse['access_token'];
            this.hidAuthDataService
              .deleteUser(
                this.userInternalId ||
                this.authService.getCurrentUser()?.userInternalId,
              )
              .subscribe(this._deletePortalUser.bind(this, successCb));
          });
      });
  }

  private _deletePortalUser(successCb?: () => void) {
    this.bankingDataService
      .deleteUser(
        this.userExternalId ||
        this.authService.getCurrentUser()?.userExternalId,
      )
      .subscribe(
        () => {
          this.loaderService.hide();
          this.authService.invalidateSession();
          this.onBoardingService.setFlowType(
            SDP_CONSTANTS.FLOW_TYPES.DELETE_ACCOUNT,
          );
          successCb?.();
        },
        (error: HttpErrorResponse) => {
          if (error.status === 404) {
            successCb?.();
          }
        },
      );
  }

  authenticateHIDApproveSecureCode(
    otp: number,
    successCb: () => void,
    errorCb: () => void,
  ) {
    this.loaderService.show();
    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.accessToken = authClientResponse['access_token'];
        this.hidAuthDataService
          .authenticateHIDApproveSecureCode(
            this.userExternalId ||
            this.authService.getCurrentUser()?.userExternalId,
            otp,
          )
          .subscribe(
            (response: any) => this._success(response, successCb),
            _ => errorCb?.(),
          );
      });
  }

  fetchUserDevices(successCb: (response: any) => void) {
    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.idToken = authClientResponse['access_token'];
        this.hidAuthDataService
          .authenticateUser()
          .subscribe((authUserResponse: any) => {
            this.accessToken = authUserResponse['access_token'];
            this.hidAuthDataService
              .getUserDevices(this.createGetUserDevicesPayload())
              .subscribe((response: any) => {
                this.loaderService.hide();
                successCb?.(response);
              });
          });
      });
  }

  deleteDevice(deviceId: number, successCb: () => void) {
    this.loaderService.show();
    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.idToken = authClientResponse['access_token'];
        this.hidAuthDataService
          .authenticateUser()
          .subscribe((authUserResponse: any) => {
            this.accessToken = authUserResponse['access_token'];
            this.hidDeviceDataService.unassignDevice(deviceId).subscribe(() => {
              this.hidDeviceDataService.deleteDevice(deviceId).subscribe(() => {
                this.loaderService.hide();
                successCb?.();
              });
            });
          });
      });
  }

  getPasswordGuidelines(successCb: (response: any) => void) {
    this.loaderService.show();

    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.idToken = authClientResponse['access_token'];
        this.hidAuthDataService
          .authenticateUser()
          .subscribe((authUserResponse: any) => {
            this.accessToken = authUserResponse['access_token'];
            this.hidAuthDataService
              .getPasswordGuidelines()
              .subscribe((response: any) => {
                this.loaderService.hide();
                successCb?.(response);
              });
          });
      });
  }

  sendTransactionSmsOtpPayload(message: any) {
    return {
      schemas: [
        "urn:hid:scim:api:idp:2.0:Action"
      ],
      "urn:hid:scim:api:idp:2.0:Action": {
        action: "DELIVER-CHALLENGE",
        attributes: [
          {
            name: "tds",
            value: message + " Please enter the code :{$secret}"
          },
          {
            name: "DEVICETYPE",
            value: "DT_TXOOB"
          },
          {
            "name": "USER.EXTERNALID",
            "value": this.userExternalId ||
              this.authService.getCurrentUser()?.userExternalId
          }]
      }
    }
  }
  validateTransactionSmsOtp(password: string, chalendId: string, successCB: () => void, errorCb: () => void) {
    this.loaderService.show();
    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.accessToken = authClientResponse['access_token'];
        this.hidAuthDataService
          .validateTransactionSmsOtp(password, this.userExternalId ||
            this.authService.getCurrentUser()?.userExternalId, chalendId)
          .subscribe(
            (response: any) => this._success(response, successCB),
            _ => errorCb?.(),
          );

      });
  }
  sendTransactionSmsOtp(message: string, successCB: (response: any) => void,
    errorCb: (response: any) => void) {
    this.loaderService.show();
    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.accessToken = authClientResponse['access_token'];
        this.hidAuthDataService.sendTransactionSigningSmsOtp
          (this.sendTransactionSmsOtpPayload(message))
          .subscribe((response: any) => {
            this.loaderService.hide();
            successCB?.(response),
              (error: HttpErrorResponse) => errorCb?.(error);;
          });
      });
  }
  sendLoginSmsOtp(successCB: (response: any) => void, errorCB: (response: any) => void) {
    this.loaderService.show();
    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.accessToken = authClientResponse['access_token'];
        this.hidAuthDataService.sendLoginSmsOtp
          (this.userExternalId || this.authService.getCurrentUser()?.userExternalId)
          .subscribe((response: any) => {
            this.loaderService.hide();
            successCB?.(response),
              (error: HttpErrorResponse) => errorCB?.(error);;
          });
      });
  }

  validateLoginSmsOtp(password: string, successCB: () => void, errorCB: () => void) {
    this.loaderService.show();
    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.accessToken = authClientResponse['access_token'];
        this.hidAuthDataService.validateLoginSmsOtp
          (password, this.userExternalId || this.authService.getCurrentUser()?.userExternalId)
          .subscribe(
            (response: any) => this._success(response, successCB),
            _ => errorCB?.(),
          );
      });
  }
  createQR(message: string, type: string, successCB: (response: any) => void) {
    this.loaderService.show();
    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.accessToken = authClientResponse['access_token'];
        this.hidAuthDataService
          .createQRcode(
            message,
            type,
          )
          .subscribe((response: any) => {
            this.loaderService.hide();
            successCB?.(response);
          });
      });
  }

  sendPushNotification(message: string, successCB: (response: any) => void) {
    this.loaderService.show();
    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.accessToken = authClientResponse['access_token'];
        this.hidAuthDataService
          .sendPushNotification(
            this.userExternalId ||
            this.authService.getCurrentUser()?.userExternalId,
            this.hidDeviceDataService.selectedDevice.id,
            message,
          )
          .subscribe((response: any) => {
            this.loaderService.hide();
            successCB?.(response);
          });
      });
  }


  authenticateHIDApproveTransSignSecureCode(
    otp: number,
    transactionSigningData: string,
    successCb: () => void,
    errorCb: () => void,
  ) {
    this.loaderService.show();
    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.accessToken = authClientResponse['access_token'];
        this.hidAuthDataService
          .authenticateHIDApproveTransSignSecureCode(
            this.hidDeviceDataService.selectedDevice.id,
            this.userExternalId ||
            this.authService.getCurrentUser()?.userExternalId,
            otp,
            transactionSigningData,
          )
          .subscribe(
            (response: any) => this._success(response, successCb),
            _ => errorCb?.(),
          );
      });
  }



  // // fido
  // request_uri: string = '';
  // csrf: string = '';

  fidoClean(){
    this.request_uri =''
    this.csrf = ''
  }

  fidoPar(  
    successCb: () => void,
    errorCb: () => void ){
    this.hidAuthDataService
    .authenticateClient()
    .subscribe((authClientResponse: any) => {
      this.accessToken = authClientResponse['access_token'];
      this.hidAuthDataService.fidoPar()
      .subscribe( (fidoParRseponse:any) =>{ 
      
        this.request_uri = fidoParRseponse.data.request_uri
        this.csrf = fidoParRseponse.csrf
        this._success({status:'ok'}, successCb)
      },
      _ => errorCb?.())

    })

  }

  fidoGetLoginChallenge(
    successCB: (response: any) => void,
    errorCb: () => void,
  ) { 
    
    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.accessToken = authClientResponse['access_token'];
        this.hidAuthDataService.fidoGetChallenge(
            this.request_uri,           
            this.userExternalId || this.authService.getCurrentUser()?.userExternalId,
            this.csrf        
          )
          .subscribe(
            (response: any) =>  successCB?.(response),
            _ => errorCb?.(),
          );
      });
  }

  fidoLogin(credentialResponse:string,
    successCb: () => void,
    errorCb: () => void,
  ) {

    this.hidAuthDataService
      .authenticateClient()
      .subscribe((authClientResponse: any) => {
        this.accessToken = authClientResponse['access_token'];
        this.hidAuthDataService.fidoVerifyChallenge(
            credentialResponse,
            this.request_uri,           
            this.userExternalId || this.authService.getCurrentUser()?.userExternalId,
            this.csrf        
          )
          .subscribe(
            (response: any) => {
              this.hidAuthDataService.fidoAuthenticate(            
                this.request_uri,           
                this.userExternalId || this.authService.getCurrentUser()?.userExternalId,
                this.csrf        
              )
              .subscribe(
                (response: any) => 
                  {
                    this.fidoClean()
                    this._success(response, successCb)
    
                  },            
                _ => errorCb?.()
          );
        })
      })     
  } 

  

  fidoGetCredentialOptions(
    successCB: (response: any) => void,
    errorCb: () => void,
  ){
  
    this.hidAuthDataService.fidoEnroll(    
            'getcredentialoptions',        
            this.request_uri,           
            this.userExternalId || this.authService.getCurrentUser()?.userExternalId,
            this.csrf        
          )
          .subscribe(
            (response: any) => 
              {
             
               successCB?.(response)

              },
            _ => errorCb?.(),
          );
  }        

  fidoRegisterCredential(
    credential: string,
    successCb: () => void,
    errorCb: () => void,
  ){
  
    this.hidAuthDataService.fidoEnroll(    
            'registercredential',        
            this.request_uri,           
            this.userExternalId || this.authService.getCurrentUser()?.userExternalId,
            this.csrf,      
            credential
          )
          .subscribe(
            (response: any) => 
              {
             
                this._success(response, successCb)

              },
            _ => errorCb?.(),
          );
  } 

  setupAuthService() {
    // In Progress, could do more
    // To boot, let's make sure attributes are present in Auth Service and create them

    if (this.setup === Number(0)) {

      // SVC_User Check

      // Permissions Check

      // Device Types Check

      // Attributes

      for (let attribute of this.AttributeIDs) {



        this.hidAuthDataService
          .authenticateClient()
          .subscribe((authClientResponse: any) => {
            this.idToken = authClientResponse['access_token'];
            this.hidAuthDataService
              .authenticateUser()
              .subscribe((authUserResponse: any) => {
                this.accessToken = authUserResponse['access_token'];
                this.hidAuthDataService.getAttribute(attribute, {})
                  .subscribe(
                    (attributeRes: any) => {
                      if (attributeRes.state === 404) {


                        this.hidAuthDataService
                          .authenticateClient()
                          .subscribe((authClientResponse: any) => {
                            this.idToken = authClientResponse['access_token'];
                            this.hidAuthDataService
                              .authenticateUser()
                              .subscribe((authUserResponse: any) => {
                                this.accessToken = authUserResponse['access_token'];
                                this.hidAuthDataService.createAttribute(attribute, {})
                                  .subscribe((attributeRes: any) => {

                                    return
                                  },
                                    _ => { return })
                              })
                          })







                      }

                    })
              })
          })
      }
    }

    // Extra Points PKCE
  }


  private _success(response: any, successCb: () => void) {
    this.loaderService.hide();
    this.accessToken = response['access_token'];

    successCb?.();
  }
}
