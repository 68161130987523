import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SDP_CONSTANTS } from '@app/core/helpers';
import { setContext } from '@app/core/interceptors/api/api.interceptor';

@Injectable({
  providedIn: 'root',
})
export class HidAuthDataService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Authenticate the Client i.e. application
   */
  authenticateClient() {
    const payload = new HttpParams().set('grant_type', 'client_credentials');

    return this.http.post(`authenticate/client`, payload, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
        tokenType: SDP_CONSTANTS.TOKEN_TYPES.BASIC,
        contentType: SDP_CONSTANTS.CONTENT_TYPES.APP_FORM_URLENCODED,
      }),
    });
  }


  authenticateCode(code: string, redirect_uri: string) {
    let payload;
    if (code && redirect_uri) {
      payload =  new HttpParams().appendAll({
        grant_type: 'autorization code',
        code: code,
        redirect_uri: redirect_uri,
      }) 
    }
    return this.http.post(`authenticate/code`, payload, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
        useIdToken: true,
        contentType: SDP_CONSTANTS.CONTENT_TYPES.APP_FORM_URLENCODED,
      }),
    });
  }


 
  cibaDelete(auth_req_id: string) {
    let payload;
    if (auth_req_id) {
      payload =  new HttpParams().appendAll({     
        auth_req_id: auth_req_id,        
      }) 
    }
    return this.http.post(`ciba/delete`, payload, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
        useIdToken: true,
        contentType: SDP_CONSTANTS.CONTENT_TYPES.APP_FORM_URLENCODED,
      }),
    });
  }




  /**
   * Authenticate the HID service user
   */
  authenticateUser(username?: string, password?: string) {
    let payload;

    if (username && password) {
      payload = new HttpParams().appendAll({
        username: username,
        password: password,
      });
    }

    return this.http.post(`authenticate/user`, payload ?? {}, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
        useIdToken: true,
        contentType: SDP_CONSTANTS.CONTENT_TYPES.APP_FORM_URLENCODED,
      }),
    });
  }

  authenticateUserPASA(username?: string) {
    let payload;

    if (username) {
      payload = new HttpParams().appendAll({
        username: username,     
      });
    }

    return this.http.post(`authenticate/userPASA`, payload ?? {}, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
        useIdToken: true,
        contentType: SDP_CONSTANTS.CONTENT_TYPES.APP_FORM_URLENCODED,
      }),
    });
  }



  /**
   * Create new user with email
   */
  createUser(payload: any) {
    return this.http.post(`user/create`, payload, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
      }),
    });
  }

  searchUser(payload: any) {
    return this.http.post(`user/search`, payload, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
      }),
    });
  }

  updateUser(payload: any, userInternalId: number) {
    return this.http.put('user/update', payload, {
      params: {
        userInternalId,
      },
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
      }),
    });
  }

  deleteUser(userInternalId: number) {
    return this.http.delete(`user/delete`, {
      params: {
        userInternalId,
      },
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
      }),
    });
  }

  /**
   * Set the email as authentication method i.e. Authenticator.
   * Activation code will be available in the response
   */
  registerAuthenticator(payload: any) {
    return this.http.post(`user/authenticator/register`, payload, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
      }),
    });
  }

  /**
   * Send, Out of Bounds OTP in email using the activation code
   */
  sendOOBEmail(requestPayload: any) {
    const payload = new HttpParams().appendAll(requestPayload);

    return this.http.post(`user/authenticator/oob-email`, payload, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
        contentType: SDP_CONSTANTS.CONTENT_TYPES.APP_FORM_URLENCODED,
      }),
    });
  }

  /**
   * Authenticate, Out of Bounds OTP received in email
   */
  authenticateOOBEmailOTP(requestPayload: any) {
    const payload = new HttpParams().appendAll(requestPayload);

    return this.http.post(
      `user/authenticator/oob-email/authenticate`,
      payload,
      {
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
          contentType: SDP_CONSTANTS.CONTENT_TYPES.APP_FORM_URLENCODED,
        }),
      },
    );
  }

  unRegisterAuthenticator(
    userInternalId: number,
    authenticatorType: string,
    payload: any,
  ) {
    return this.http.post(`user/authenticator/unregister`, payload, {
      params: {
        userInternalId,
        authenticatorType,
      },
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
      }),
    });
  }

  getPasswordGuidelines() {
    return this.http.get(
      `authenticator/password/guidelines`,
      Object.assign({
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
        }),
      }),
    );
  }

  resetPassword(authenticatorId: string, payload: any) {
    return this.http.put(`user/password/reset`, payload, {
      params: {
        authenticatorId,
      },
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
      }),
    });
  }

  getUserDevices(payload: any) {
    return this.http.post('user/device/search', payload, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
      }),
    });
  }

  createQRcode(   
    message: string,
    type:string
  ) {
    return this.http.post(
      'hid-approve/qr/create',
      {    
        message, type,
      },
      {
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
        }),
      },
    );
  }
  sendPushNotification(
    userExternalId: string,
    deviceInternalId: number,
    message: string,
  ) {
    return this.http.post(
      'hid-approve/notification/send',
      {
        userExternalId,
        deviceInternalId,
        message,
      },
      {
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
        }),
      },
    );
  }

  authenticateHIDApproveSecureCode(userExternalId: string, otp: number) {
    return this.http.post(
      'hid-approve/authenticate/otp',
      {
        password: otp,
      },
      {
        params: {
          userExternalId,
        },
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
        }),
      },
    );
  }

  sendTransactionSigningSmsOtp(payload: any){
    return this.http.post(
      'transactionsingning/send/smsotp', payload,
      {
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
        }),
      },
    );
  } 
  
  validateTransactionSmsOtp(password: string, userExternalId: string, txId: string){
   return this.http.post('validate/transaction/sms_otp', 
   {
     password: password,
     username: userExternalId,
     txId:txId
    },
    {
      context: setContext ({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
      }),
    });
  }

  sendLoginSmsOtp(userExternalId: string) {
    return this.http.post('send/login/smsotp',{
      username: userExternalId
    },
    {
    context: setContext ({
      apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
    }),
  });
  }

  validateLoginSmsOtp(password: string, userExternalId: string){
    return this.http.post('validate/login/smsotp', {
      username: userExternalId,
      password: password
    },
    {
    context: setContext ({
      apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
    }),      
    })
  }
  authenticateHIDApproveTransSignSecureCode(
    deviceId: number,
    userExternalId: string,
    otp: number,
    data: any,
  ) {
    return this.http.post(
      'hid-approve/transactionsigning/authenticate/otp',
      {
        password: otp,
        data,
      },
      {
        params: {
          deviceId,
          userExternalId,
        },
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
        }),
      },
    );
  }

  getAttribute(attributeId:string, payload: any) {
    return this.http.post(`attribute/get`, payload, {
        params: {
          attributeId: attributeId,
        },
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
        }),
      });
    }
  
  createAttribute(attributeId:string, payload: any) {
    return this.http.post(`attribute/create`, payload, {
      params: {
        attributeId: attributeId,
      },
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
      }),
    });
  }

 
  fidoPar() {
    return this.http.post(`fido/par`, {}, {
      params: {},
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
      }),
    });
  } // ok

  fidoGetChallenge(

    request_uri:string,
    username:string,
    csrf:string,  
    ) {
    return this.http.post(`/fido/getchallenge`, {
     
      request_uri:request_uri,
      username:username,
      csrf:csrf, 
    }, {
      params: {
       
      },
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
      }),
    });
  } // 

  fidoVerifyChallenge(  
    credentialResponse:string,
    request_uri:string,
    username:string,
    csrf:string,  
    ) {
    return this.http.post(`/fido/verifychallenge`, {
      credential_response:credentialResponse,
      request_uri:request_uri,
      username:username,
      csrf:csrf, 
    }, {
      params: {
       
      },
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
      }),
    });
  }
  fidoAuthenticate(  
    request_uri:string,
    username:string,
    csrf:string,  
    ) {
    return this.http.post(`/fido/authenticate`, {
      
      request_uri:request_uri,
      username:username,
      csrf:csrf, 
    }, {
      params: {
       
      },
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
      }),
    });
  } 

  fidoEnroll(  
    enroll_step:string,
    request_uri:string,
    username:string,
    csrf:string,  
    credential?:string  
  ) {
    return this.http.post(`/fido/enrollfido`, {
      enroll_step:enroll_step,
      request_uri:request_uri,
      username:username,
      csrf:csrf, 
      credential:credential
    }, {
      params: {
       
      },
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
      }),
    });
  } // ok


}
