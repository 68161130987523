import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { SDP_CONSTANTS } from '@core/helpers';

export class ValidationService {
  // Returns the translation key of validation error.
  // The relevant value will be picked up by translation service and shown in the UI
  // All the keys are configured in en.json.
  static getValidationErrorMessage(validatorName: string) {
    let config: any = {
      required: 'validations.required',
      invalidEmailAddress: 'validations.invalidEmail',
      minlength: 'validations.minlength',
      maxLength: 'validations.maxLength',
      mustMatch: 'validations.mustMatch',
      min: 'validations.min',
      max: 'validations.max',
      fundsTransferAmountMoreThanBalance:
        'validations.fundsTransferAmountMoreThanBalance',
      toAndFromAccountMustBeDifferent:
        'validations.toAndFromAccountMustBeDifferent',
    };

    return config[validatorName];
  }

  static emailValidator(control: FormControl) {
    if (control.value.match('/hidglobal.com/gi')) {
          return { invalidEmailAddress: true };
   } 
    if (control.value.match(SDP_CONSTANTS.REGEX.EMAIL)) {
      
      return null;
    } else {
      return { invalidEmailAddress: true };
    }
  }

  // Used in create password for valdating password and confirm password.
  // Used at form group level.
  static passwordValidator(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const password = form.get('passwordCreate');
      const confirmPassword = form.get('confirmPassword');

      return password &&
        confirmPassword &&
        password.value === confirmPassword.value
        ? null
        : { mustMatch: true };
    };
  }
}
