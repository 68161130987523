import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { Base } from '@app/core/base/base';
import { SDP_CONSTANTS } from '@app/core/helpers';
import { FundsTransfer } from '@app/core/models/portal/funds-transfer';
import {
  SharedService,
  HidAuthenticationService,
  HidRmsService,
  AuthService,
  HidDeviceDataService,
  LoaderService,
  OnboardingService,
} from '@app/core/services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
@Component({
  selector: 'app-hid-approve',
  templateUrl: './hid-approve.component.html',
  styleUrls: ['./hid-approve.component.scss'],
})
export class HidApproveComponent extends Base implements OnInit {
  // Used to check if hid approve component is being
  // used as part of a flow or in standalone
  @Input() standalone = true;
  @Input() translationRequired = true;
  @Input() isAddPayee!: boolean;
  @Input() isEditUser!: boolean;
  @Input() trustedDevice!: boolean;
  @Input() deleteUserAccount!: boolean;
  @Input() activeView!: string;
  @Input() pushMessage!: string;
  @Input() message!: string;
  @Input() successScreen = false;
  @Input() backBtn = false;
  @Input() cancelBtn = false;
  @Input() successScreenMessage!: string;
  @Input() successScreenActionBtnText!: string;
  @Input() finalView!: string;
  @Input() transactionSigning = false;
  @Input() fundsTransferInfo!: FundsTransfer;
  @Input() showProcessing = false;
  @Input() login = false;
  @Input() set processingDone(processingDone: boolean) {
    if (processingDone !== undefined && processingDone && this.successScreen) {
      this.loaderService.hide();
      this.activeView = SDP_CONSTANTS.VIEWS.FLOW_END;
    }
  }
  @Input() useOfflineCode: boolean = false;
  @Input() useSecureCode: boolean = false;
  @Output() showhidapprovetargetview = new EventEmitter();
  @Output() flowend = new EventEmitter();
  @Output() rejected = new EventEmitter();
  @Output() cancelled = new EventEmitter();

  noDeviceFound = false;
  secureCodeDesc!: string;
  devices!: any[];

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly loaderService: LoaderService,
    private readonly onBoardingService: OnboardingService,
    private readonly hidAuthService: HidAuthenticationService,
    private readonly hidDeviceDataService: HidDeviceDataService,
    private readonly translateService: TranslateService,
    private readonly hidRmsService:HidRmsService,
    private readonly authService:AuthService,
    
  ) {
    super();
  }

  ngOnInit(): void {
  

  
      this.hidAuthService.fetchUserDevices(
        this._fetchUserDevicesSuccess.bind(this),
      );
  
    this._getSecureCodeDescription();
   
    if (this.useSecureCode){
      this.viewchange.emit(SDP_CONSTANTS.VIEWS.HID_APPROVE_TRANS_OTP);
    }
    if (this.useOfflineCode){
      this.viewchange.emit(SDP_CONSTANTS.VIEWS.HID_APPROVE_TRANS_OTP);
    }
  }

  private _fetchUserDevicesSuccess(response: any) {
    if (response.totalResults === 0) {
      this.noDeviceFound = true;
    } else {
      this._checkDevices(response.resources);
    }

    this.cd.detectChanges();
  }

  private _getSecureCodeDescription() {
    if (this.isAddPayee) {
      this.secureCodeDesc = this.translateService.instant(
        'hidApproveOtp.addPayee',
      );
    } else if (this.isEditUser) {
      this.secureCodeDesc = this.translateService.instant(
        'hidApproveOtp.editUser',
      );
    } else if (this.trustedDevice) {
      this.secureCodeDesc = this.translateService.instant(
        'hidApproveOtp.deleteTrustedDevice',
      );
    } else if (this.deleteUserAccount) {
      this.secureCodeDesc = this.translateService.instant(
        'hidApproveOtp.deleteUserAccount',
      );
    } else {
      this.secureCodeDesc = this.translateService.instant(
        'hidApproveOtp.description',
      );
    }
  }

  private _checkDevices(devices: any[]) {
    const deviceTypes = Object.values(SDP_CONSTANTS.PUSH_DEVICE_TYPES);

    const filteredDevices = devices.filter(
      (device: any) =>
        device.status.active && deviceTypes.includes(device.type),
    );

    this.devices = filteredDevices;

    const numberOfDevices = filteredDevices.length;

 /// rewrite next thing, oh my god.
 // rewritten past thing, thank me

 
    if( numberOfDevices === 1){ this.hidDeviceDataService.selectedDevice = filteredDevices[0];     } // autoselects device if only 1

    if (this.hidDeviceDataService.selectedDevice) { // selected device - executing approval
      

      if(!this.useOfflineCode)  {
        this.activeView = SDP_CONSTANTS.VIEWS.HID_APPROVE_PUSH;
      } else {
        this.activeView = SDP_CONSTANTS.VIEWS.HID_APPROVE_TRANS_OTP 
      }
    } else { // device not selected
    
      if (numberOfDevices === 0) { // no device
     
        this.noDeviceFound = true;
      } else if (numberOfDevices > 1)  { // executing device selection process
       
        this.hidDeviceDataService.devices = filteredDevices;
        this.activeView = SDP_CONSTANTS.VIEWS.SELECT_PRIMARY_DEVICE;
      }
    }

  }

  onViewChange(view: any) {
   
    if (
      [
        SDP_CONSTANTS.VIEWS.HID_APPROVE_PUSH,
        SDP_CONSTANTS.VIEWS.HID_APPROVE_OTP,
        SDP_CONSTANTS.VIEWS.HID_APPROVE_TRANS_OTP,
      ].includes(view)
    ) {
      this.activeView = view as string;
    } else {
      this.viewchange.emit(view);
    }
  }




  _RMSPaymentSignPush(){

    let app_payment_id = ''
    let scoring = this.hidRmsService.getPaymentScore()
    if( scoring.scoring_result ) { let e23 = scoring.scoring_result; if (e23.payments) { let e24 = e23.payments[0]; if (e24.app_payment_id) { app_payment_id = e24.app_payment_id } } } 

    
    let sigdate = new Date().toISOString().slice(0,10)
    

    
    const nowu = this.hidAuthService.getUser()
    let tokenid = ''
    if (nowu.phoneno) { let ccdscd = nowu.phone; if (ccdscd.number){ tokenid = ccdscd.number} }
    tokenid = 'ApproveOTP' + tokenid
    this.hidRmsService.paymentSign(nowu.userName, this.authService.getVisit()?.tttt, app_payment_id, "open", "accepted" ,sigdate, tokenid, 'mtoken', ()=>{})
  
  }




  onShowHIDApproveTargetView() {
     this.hidDeviceDataService.selectedDevice = ''
    if (this.showProcessing) {
      this.showhidapprovetargetview.emit();
      this.loaderService.show();
    } else {
      if (this.successScreen) {      
         if(this.transactionSigning){ this._RMSPaymentSignPush()}
         

        this.activeView = SDP_CONSTANTS.VIEWS.FLOW_END;
      } else {
        this.showhidapprovetargetview.emit();
      }
    }
  }
  
 
  private _rmsDataNo(){ 
    this.rejected.emit();
  }
  onFlowEnd() {
    this.hidDeviceDataService.selectedDevice = ''
    this.successScreen = false;
    this.flowend.emit();
  }

  onRejected() {
    this.successScreen = false;
    if (environment.module_rms) {      
      const nowu = this.hidAuthService.getUser()
      if (this.login) {
      this.hidRmsService.loginStep(nowu.userName, 2,'login_denied', 'mtoken' , nowu.userInternalId,this.authService.getVisit()?.tttt, this._rmsDataNo.bind(this))   
      }
    }
    this.rejected.emit();
    this.hidDeviceDataService.selectedDevice = ''
  }

  deleteUser() {
    this.hidAuthService.deleteCurrentUser(this._deleteUserSuccess.bind(this));
  }

  private _deleteUserSuccess() {
    this.hidDeviceDataService.selectedDevice = ''
    this.onBoardingService.email = '';
    this.viewchange.emit(SDP_CONSTANTS.VIEWS.REGISTER);
  }

  onCancel() {
    this.hidDeviceDataService.selectedDevice = ''
    this.cancelled.emit();
  }
}
