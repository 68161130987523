import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SDP_CONSTANTS } from '@core/helpers';
import { Base } from '@app/core/base/base';
import { SharedService } from '@core/services';
// import { AddDeviceBase } from '../add-device-base.component';
import { TranslateService } from '@ngx-translate/core';
interface IAuthenticationType {
  title: string;
  imageFileName: string;
  selected: boolean;
  isDisabled: boolean;
}

@Component({
  selector: 'app-adaptive-authentication',
  templateUrl: './adaptive-authentication.component.html',
  styleUrls: ['./adaptive-authentication.component.scss']
})

export class MFAv2AdaptiveAuthenticationComponent extends Base implements OnInit 
{


  LibraryAuthTypes = {
    1: [      
      {
         title: SDP_CONSTANTS.CHOOSE_AUTHENTICATION.SMS,
          imageFileName: 'sms',
         selected: false,
          isDisabled: true,
       },   
       {
        title: SDP_CONSTANTS.CHOOSE_AUTHENTICATION.HID_APPROVE,
        imageFileName: 'approve',
        selected: false,
        isDisabled: false,
      },
    ],
    2:[
      {
        title: SDP_CONSTANTS.CHOOSE_AUTHENTICATION.HID_APPROVE,
        imageFileName: 'approve',
        selected: false,
        isDisabled: false,
      },
      {
        title: SDP_CONSTANTS.CHOOSE_AUTHENTICATION.HID_APPROVE_CODE,
        imageFileName: 'approve',
        selected: false,
        isDisabled: false,
        },   

    ],
  }



  @Input() factor = 0; // may be 1 or 2 or 99
  @Input() standalone = false;
  @Input() addPayee = false;
  @Input() cancelBtn = false;
  @Input() loginAdaptive = false;
  @Output() cancelled = new EventEmitter();
  isSmsClicked = false;
  isAuthChecked = false;
  isHIDClicked = false;

  authDisable = false;

  // Contains all the authentication types.
  // HID Approve, Email, SMS, Hardware Token
  authenticationTypes!: IAuthenticationType[];

  // Contains only active authentication types
  // filtered by isDisabled flag
  activeAuthTypes!: IAuthenticationType[];


  constructor(
    private readonly sharedService:SharedService,
    private readonly translateService: TranslateService,
  ) {
    
    super();
  }
  ngOnDestroy() {
    
  }
  ngOnInit(): void {   
   
    this.createAuthenticationType();
    this._setActiveAuthTypes();

  
  }

  
  createAuthenticationType() {
    if (this.factor === 1) {
       this.authenticationTypes = this.LibraryAuthTypes[1]
    }
    if (this.factor === 2) {
      this.authenticationTypes = this.LibraryAuthTypes[2]
    }
  }

  private _setActiveAuthTypes() {   
    this.activeAuthTypes = this.authenticationTypes.filter(
      (authType: IAuthenticationType) => !authType.isDisabled,
    );
  }

  onSubmit(authType: IAuthenticationType) {
    switch(authType.title){
      case SDP_CONSTANTS.CHOOSE_AUTHENTICATION.HID_APPROVE:
        this.viewchange.emit(SDP_CONSTANTS.MFAV2_USECASES.HID_APPROVE_PUSH);
      break
      ;;
      case SDP_CONSTANTS.CHOOSE_AUTHENTICATION.HID_APPROVE_CODE:
        this.viewchange.emit(SDP_CONSTANTS.MFAV2_USECASES.HID_APPROVE_CODE);
      break
      ;;
      case SDP_CONSTANTS.CHOOSE_AUTHENTICATION.FIDO:
        this.viewchange.emit(SDP_CONSTANTS.MFAV2_USECASES.FIDO);
      break
      ;;
      default: {
      return;
      }
    } 
  }


  isValid() {   
    return (
      this.activeAuthTypes.filter(
        (authType: IAuthenticationType) => authType.selected,
      )?.length === 1
    );
    
  }

  onCancelClick() {
    this.cancelled.emit();
  }
}

