

<header>

<ng-container *ngIf="!(alive)">


  <div class="default-mainpage-textbox">
    <font class="default-mainpage-font">Greetings,<br />we are working on updating this site, please come back in a bit.<br /><a href="mailto://iamsales@hidglobal.com">- HID</a></font>
    </div>


    <div class="default-mainpage-header">
      <div class="default-mainpage-logo majorbank-logo-blue">
          Site Will Refresh Automatically
      </div>     
    </div>
</ng-container>


 <ng-container *ngIf="idvonly">  
  <ng-container *ngTemplateOutlet="IDVOnly"></ng-container>
</ng-container>

<ng-container *ngIf="!(idvonly)">  
  <ng-container *ngIf="(theme == 'default')">    <ng-container *ngTemplateOutlet="BankingToo"></ng-container>  </ng-container>
  <ng-container *ngIf="(theme == 'majorcompany')">    <ng-container *ngTemplateOutlet="MajorCompany"></ng-container>  </ng-container>
  <ng-container *ngIf="(theme == 'idv')">    <ng-container *ngTemplateOutlet="IDVOnly"></ng-container>  </ng-container>
</ng-container>

  <ng-container *ngIf="optionsShow">  
    <ng-container *ngTemplateOutlet="HIDSidebarTemplate"></ng-container>
  </ng-container>

</header>








<ng-template #HIDSidebarTemplate>

  <div class="fullscreen-blocker" (click)="onToggleSettings()"
  style="position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: ' ';
    z-index:500;
    background: rgba(0,0,0,.5);">
</div>

<div class="security-sidebar">
  
  <div >
  <ng-container *ngFor="let product of products">       
    <a *ngIf="(product.enabled)"
      class="btn btn-outline-light      
      "
      style="margin:2%; padding: 2% 2% 2% 2%; width: 95%;"
      appHoverImg
      [href]="product.redirectLink"  
      target="_blank" rel="noopener noreferrer"      
    >        
     {{ product.title | translate }}
    </a>
    <br/>     
  </ng-container>
     

         
  <div class="card sidebard-card" *ngIf="rmsScoreShow">
    <div class="card-body">      
      
      <h5 class="card-title">Dynamic HID RMS Analysis</h5> 
      <ng-container *ngIf="rmsscore">           
      <div>  
        <div *nfIf="rmsscore.data.all_data_collected">
               All Data Collected
        </div>          
      

        <div>
          Risk Score: <b>{{ rmsscore.scoring_result.risk }}</b><br />
          
          Detections:<br />
          <ng-container *ngFor="let detection of rmsscore.scoring_result.session.standalone_signals; let last = last">     
              - {{ detection.type }} <br />
          </ng-container>
        </div>  
        <br/>
        <button class="btn  btn-outline-info btn-sm"(click)="onToggleDetail1()" id="bt1">
          {{buttonName1}}
       </button>
        <ng-container *ngIf="optionsShow1">
        <pre> 
          {{ rmsscore | json }}
        </pre>
      </ng-container>
        
      </div>       
      </ng-container>
    </div>
  </div>

  <div class="card sidebard-card" *ngIf="rmsShow">
      <div class="card-body">
        <h5 class="card-title">Dynamic HID RMS Fingerprinting</h5> 
        
        
        <ng-container *ngIf="rmsdata">       

        <div>       
          <div *ngIf="rmsdata.isp">
           ISP: <b> {{ rmsdata.isp }} </b>
           </div>
           <div *ngIf="rmsdata.geoip">
            City: <b> {{rmsdata.geoip.city}} </b><br/>
            Country: <b> {{rmsdata.geoip.country_code}}
            </b>   
           </div>
            <!-- GoogleMaps for LongLat -->
           <div id="gmapsbracket"></div>
           <br />


          <button class="btn btn-outline-info btn-sm"(click)="onToggleDetail2()" id="bt2">
            {{buttonName1}}
         </button>
          <ng-container *ngIf="optionsShow2">
          <pre> {{ rmsdata | json }}
          </pre>
          </ng-container>
         </div>       
        </ng-container>
      </div>
    </div>


      

     


    </div>

  </div>




</ng-template>


<ng-template #IDVOnly>

  
  <nav [ngClass]="(makeClass('sdp-','-navbar') + ' navbar navbar-light ')">
    <div class="d-flex justify-content-start">   
      <a class="navbar-brand" href="#">
        <div
        src=""
        class="hid-logo hid-brand-logo"
        alt="HID Logo"
        >
        ></div>
      </a>    
    </div>

    <div class="d-flex justify-content-end align-items-center">

      <button class="btn btn-outline-dark  btn-sm" (click)="onToggleSettings()" id="bt">
      {{buttonName}}
      </button>  
    </div>
  </nav>
</ng-template>

<ng-template #MajorCompany>

  
  <nav [ngClass]="(makeClass('sdp-','-navbar') + ' navbar navbar-light ')">
    <div class="d-flex justify-content-start">   
      <a class="navbar-brand" href="#">
        <div
        src=""
        class="hid-logo hid-brand-logo"
        alt="HID Logo"
        >
        ></div>
      </a>    
    </div>

    <div class="d-flex justify-content-end align-items-center">

      <button class="btn btn-outline-dark  btn-sm" (click)="onToggleSettings()" id="bt">
      {{buttonName}}
      </button>  
    </div>
  </nav>
</ng-template>

<ng-template #BankingToo>  



  
  <nav [ngClass]="(makeClass('sdp-','-navbar') + ' navbar navbar-light ')">
    <div class="d-flex justify-content-start">

        <a class="navbar-brand" href="#">
          <div
          src=""
          class="majorbank-logo-white brand-logo"
          alt="Major Bank Logo"
          >
          ></div>
        </a>
    </div>

    <div class="d-flex justify-content-end align-items-center">
     <button class="btn btn-outline-light btn-sm" (click)="onToggleSettings()" id="bt">
      {{buttonName}}
     </button>
    <a
      class="sdp-dummy-link-white btn  btn-sm" 
      style="margin:5px;"
      (click)="onLogoutBtnClick()"
      *ngIf="logoutEnabled | async"
      title="Logout"
      aria-label="Logout"
      >Logout</a
    >
    </div>
  </nav>
</ng-template>