import Swal from 'sweetalert2';

/**
 * SweetAlert2 npm package is used for showing custom alert/confirm/prompt dialogs.
 * SdpAlert is a custom utility with overridden configs for sweet-alert.
 */
export const SdpAlert = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary-mb',
    cancelButton: 'btn btn-link-primary',
    title: 'sdp-swal-title-cls',
    actions: 'sdp-swal-action-cls',
  },
  buttonsStyling: false,
  reverseButtons: true,
  allowOutsideClick: false,
  allowEscapeKey: false,
  showCancelButton: true,
  cancelButtonText: 'CANCEL',
  confirmButtonText: 'CONTINUE',
});
