<div class="manual-reg-container">
  <div class="sdp-ob-form-header">
    <h3 class="sdp-ob-title">
      {{ stepItems[activeStepIndex].label | translate }}
    </h3>
    <p class="sdp-ob-intro" *ngIf="activeStepIndex !== stepItems.length - 1">
      {{ 'manualRegistration.description' | translate }}
    </p>
  </div>

  <ng-container
    *ngFor="let form of masterForm; let i = index; trackBy: trackByFn"
  >
    <form
      action=""
      [formGroup]="masterForm[i]"
      *ngIf="i === activeStepIndex || masterForm.length === 1"
      (keypress)="onEnter($event)"
    >
      <div class="">
        <div class="fields-container">
          <div
            *ngFor="let field of formFields[i]; trackBy: trackByFn"
            [ngSwitch]="currentFormContent[i][field].type"
          >
            <div class="form-group full-width">
              <label
                class="field-label text-bold"
                [for]="field"
                [hidden]="currentFormContent[i][field].hidden"
                >{{ currentFormContent[i][field].placeholder | translate }}
                <span *ngIf="currentFormContent[i][field].validations.required"
                  >*</span
                >
                <span
                  class="optional"
                  *ngIf="!currentFormContent[i][field].validations.required"
                >
                  (optional)</span
                >
              </label>
              <ng-container *ngSwitchCase="'select'">
                <select
                  [formControlName]="field"
                  [id]="field"
                  class="form-control"
                >
                  <option value="">
                    {{ 'manualRegistration.selectCountry' | translate }}
                  </option>
                  <option
                    *ngFor="
                      let option of currentFormContent[i][field].options;
                      trackBy: trackByFn
                    "
                    [value]="option.name"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </ng-container>
              <ng-container *ngSwitchCase="'phone'">
                <div>
                  <ngx-intl-tel-input
                    [cssClass]="'form-control'"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[
                      SearchCountryField.Iso2,
                      SearchCountryField.Name
                    ]"
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="selectedCountry"
                 
                    [phoneValidation]="false"
                    [separateDialCode]="true"
                    [numberFormat]="PhoneNumberFormat.International"
                    name="phone"
                    [formControlName]="field"
                    (ngModelChange)="onTelephoneNumberChange($event)"
                  >
                  </ngx-intl-tel-input>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'date'">
                <input
                  [max]="maxDate"
                  [type]="currentFormContent[i][field].type"
                  [formControlName]="field"
                  [id]="field"
                  class="form-control"
                />
              </ng-container>
              <ng-container *ngSwitchDefault>
                <input
                  maxlength="50"
                  [type]="currentFormContent[i][field].type"
                  [formControlName]="field"
                  [id]="field"
                  class="form-control"
                  [hidden]="currentFormContent[i][field].hidden"
                  [readonly]="currentFormContent[i][field].disabled"
                />
              </ng-container>
              <p
                class="validation-error"
                *ngIf="
                  masterForm[i].get(field) &&
                  (masterForm[i].get(field)!.dirty ||
                    masterForm[i].get(field)!.touched) &&
                  masterForm[i].get(field)!.invalid &&
                  masterForm[i].get(field)!.errors
                "
              >
                {{ getValidationMessage(i, field) }}
              </p>
            </div>
          </div>
        </div>

        <div
          class="preview-container"
          *ngIf="activeStepIndex === stepItems.length - 1"
        >
          <ng-container *ngFor="let form of masterForm; let j = index">
            <label *ngIf="j !== stepItems.length - 1" class="step-label" ngI>{{
              stepItems[j].label | translate
            }}</label>
            <em
              class="step-edit"
              *ngIf="j !== stepItems.length - 1"
              (click)="editDetails(j)"
              >{{ 'manualRegistration.edit' | translate }}</em
            >

            <div class="">
              <div class="fields-container">
                <div *ngFor="let field of formFields[j]">
                  <div
                    class="form-group full-width"
                    *ngIf="field !== 'addressLine1' && field !== 'addressLine2'"
                  >
                    <label class="field-label text-bold" [for]="field"
                      >{{ currentFormContent[j][field].placeholder | translate
                      }}<span>: </span>
                    </label>
                    <span
                      class=""
                      *ngIf="field !== 'telephoneNumber'"
                      >{{ formData[field] || 'N/A' }}</span
                    >
                    <span
                      class=""
                      *ngIf="field === 'telephoneNumber'"
                      >{{ formData[field].phoneNumber || 'N/A' }}</span
                    >
                  </div>
                </div>
                <hr *ngIf="j < 2" />
              </div>
            </div>
          </ng-container>
        </div>

        <small class="text-danger" *ngIf="error$$ | async as error">{{
          error
        }}</small>

        <div class="button-container">
          <button
            type="button"
            class="btn btn-outline-primary mr-3"
            *ngIf="activeStepIndex === 0"
            (click)="onCancel()"
          >
            {{ 'cancel' | translate }}
          </button>
          <button
            type="button"
            class="btn btn-outline-primary mr-3"
            [disabled]="!activeStepIndex"
            *ngIf="
              activeStepIndex !== 0 && activeStepIndex !== stepItems.length - 1
            "
            (click)="goToStep('prev')"
          >
            {{ 'manualRegistration.back' | translate }}
          </button>
          <button
            class="btn btn-primary-mb mr-3 submit-btn"
            type="button"
            [disabled]="
              activeStepIndex === stepItems.length - 1 || masterForm[i].invalid
            "
            *ngIf="activeStepIndex !== stepItems.length - 1"
            (click)="goToStep('next')"
          >
            {{ 'manualRegistration.next' | translate }}
          </button>
          <button
            class="btn btn-primary-mb submit-btn"
            *ngIf="activeStepIndex === stepItems.length - 1"
            type="button"
            (click)="onSubmitClick()"
          >
            {{ 'manualRegistration.confirmAndSubmit' | translate }}
          </button>
        </div>
      </div>
    </form>
  </ng-container>
</div>
