import { SDP_CONSTANTS } from '@app/core/helpers';
import { countries } from '@app/core/helpers/country';

interface Country {
  name: string;
  code: string;
}

export function createCountryList(sourceCountries: any[]) {
  let allCountries: Country[] = [];
  sourceCountries.forEach((c: any) => {
    const country = {
      name: c[0].toString(),
      code: c[1].toString(),
    };
    allCountries.push(country);
  });
  return allCountries;
}


const PersonalDetailStep = {
  telephoneNumber: {
    type: 'phone',
    validations: {
      required: true,
    },
    errors: {
      required: 'manualRegistration.telephoneRequired',
    },
    placeholder: 'manualRegistration.telephone',
  },
};


let Alldata = {};
Alldata = Object.assign(Alldata,PersonalDetailStep)

export const STEP_SINGLE = [
  { label: 'manualRegistration.step4', data: Alldata },
];


export const STEP_ITEMS = [
  { label: 'manualRegistration.step1', data: PersonalDetailStep },
  { label: 'manualRegistration.step4', data: {} },
];
