import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpContextToken,
  HttpContext,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HidAuthenticationService, HidIdvaService } from '@app/core/services';
import { SDP_CONSTANTS } from '@app/core/helpers';

const CONSTS = {
  AUTHORIZATION: 'Authorization',
  CONTENT_TYPE: 'Content-Type',
  I18N_ASSETS_PATH: './assets/i18n',
  API: '/api',
};

export interface SdpHttpContext {
  apiType?: string;
  tokenType?: string;
  useIdToken?: boolean; // only for hid auth service
  contentType?: string;
}

const defaultSdpHttpContext: SdpHttpContext = {
  apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
  contentType: SDP_CONSTANTS.CONTENT_TYPES.APP_JSON,
  tokenType: SDP_CONSTANTS.TOKEN_TYPES.BEARER,
  useIdToken: false,
};

const SDP_CONTEXT_TOKEN = new HttpContextToken<SdpHttpContext>(
  () => defaultSdpHttpContext,
);

/**
 * Sets the context.
 * @param context: SdpHttpContext
 * @returns HttpContext
 */
export function setContext(context: SdpHttpContext) {
  return new HttpContext().set(
    SDP_CONTEXT_TOKEN,
    Object.assign({}, defaultSdpHttpContext, context),
  );
}

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private readonly hidIdvaService: HidIdvaService,
    private readonly hidAuthService: HidAuthenticationService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const context = request.context.get(SDP_CONTEXT_TOKEN);
    const url = this._getBaseUrl(request, context.apiType);
    const authToken = this._getAuthToken(context);

    const req = request.clone({
      url,
      headers: request.headers
        .append(CONSTS.AUTHORIZATION, authToken)
        .append(
          CONSTS.CONTENT_TYPE,
          context.contentType || SDP_CONSTANTS.CONTENT_TYPES.APP_JSON,
        ),
    });

    return next.handle(req);
  }

  private _getBaseUrl(
    request: HttpRequest<unknown>,
    apiType = SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
  ) {
    let baseUrl = '';

    if (!request.url.includes(CONSTS.I18N_ASSETS_PATH)) {
      switch (apiType) {
        case SDP_CONSTANTS.API_TYPES.HID_AUTH_API:
          baseUrl = environment.apiBaseUrls.hidAuthService;
          break;

        case SDP_CONSTANTS.API_TYPES.HID_IDVA_API:
          baseUrl = environment.apiBaseUrls.hidIdvaService;
          break;
          case SDP_CONSTANTS.API_TYPES.HID_HW_API:
            baseUrl = environment.apiBaseUrls.hidHwService;
            break;

        case SDP_CONSTANTS.API_TYPES.HID_RMS_API:
            baseUrl = environment.apiBaseUrls.hidRmsService;
        break;

                 
        case SDP_CONSTANTS.API_TYPES.HID_ARTS_API:
              baseUrl = environment.apiBaseUrls.hidArtsService;
              break;

        case SDP_CONSTANTS.API_TYPES.PORTAL_API:
          baseUrl = environment.apiBaseUrls.portalApiService + CONSTS.API;
          break;
      }
    }

    baseUrl = `${baseUrl}/${request.url}`;

    return baseUrl;
  }

  private _getAuthToken(context: SdpHttpContext) {
    let authToken = '';

    switch (context.apiType) {
      case SDP_CONSTANTS.API_TYPES.HID_AUTH_API:
        if (context.tokenType === SDP_CONSTANTS.TOKEN_TYPES.BEARER) {
          if (context.useIdToken) {
            authToken = `${SDP_CONSTANTS.TOKEN_TYPES.BEARER} ${this.hidAuthService.idToken}`;
          } else {
            authToken = `${SDP_CONSTANTS.TOKEN_TYPES.BEARER} ${this.hidAuthService.accessToken}`;
          }
        }
        break;
      
    }

    return authToken;
  }
}
