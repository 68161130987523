import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { HidHwDataService } from '../hid-hw-data/hid-hw-data.service'
import {
  AuthService,
  HidAuthenticationService,
  LoaderService,
  HidAuthDataService,
  HidDeviceDataService,
} from '@app/core/services';
import { HIDDeeplink , HIDDeeplinkStore,  HIDDeeplinkUserStore, HIDMonitorDeeplinkResponse, HIDgenerateDeeplinkResponse} from '@app/core/models/hid-hw-message.interface';

@Injectable({
  providedIn: 'root',
})
export class HidHwService {
  checker!:any;
  accessToken!: string;
  userStore!: HIDDeeplinkUserStore;
  deeplinkStore!: HIDDeeplinkStore;
  

  constructor(
    private readonly hidHwDataService: HidHwDataService    
  ) {
    
    this.checker = setInterval(()=>{ this.checkDeeplinks() }, 2000 )



  }

    checkDeeplinks(){
      this.hidHwDataService.monitorDeeplinks()
            .subscribe(
                (response:HIDMonitorDeeplinkResponse) => {
                if (response.userStore){       this.userStore = response.userStore        }
                if (response.deeplinkStore){   this.deeplinkStore = response.deeplinkStore }
              },
              _ => {}, 
            )
    }

    listDeeplinks(){
      return this.deeplinkStore
    }
    listUsers(){
      return this.userStore
    }
 
    generateDeeplink(
      action: string,
      userid:string,
      tdsData?: string,
      ){
      this.hidHwDataService.generateDeeplink(
        action, userid, tdsData
      ).subscribe(
        (response:HIDgenerateDeeplinkResponse) => {
          return response
      },
      _ => {}, 
    )
    }
    
    removeDeeplink(ref:string){
      this.hidHwDataService.removeDeeplink(ref).subscribe((response:string) => {
        return response
    },
    _ => {}, )
    }

  }









